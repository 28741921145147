<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
        <el-alert type="info" :closable="false">
          <template v-slot:title>
            <p style="font-size: 16px; color: black">
              <strong>注意:</strong> 在整个实验过程中，请 <b style="color: darkred;">不要</b> 刷新浏
              览器。否则将丢失所有数据。
            </p>
          </template>
        </el-alert>
      </el-header>
      <el-main>

        <!-- page1 简介 -->
        <div v-show="concurrentPage === 1" id="index_home" class="page-content">
          <div class="welcome-section">
            <h3>欢迎大家参与本次实验！</h3>
            <p> 在这个实验中，你将看到75个人的个人资料, 包括：1.家庭背景特征、2.学习行为特征、3.学习表现特征。
              <br><strong>您的任务是：</strong>预测学生在某门课程上的学习成绩（G3），满分为20分。
            </p>
            <ul>
              <li>在前25项任务中，您需要独立做出预测。</li>
              <li>在接下来的25项任务中，您将获得一名人工智能（AI）助手。
                AI助手会为每项任务提供建议，<strong>但请注意</strong>，AI并不总是准确的，您可以自行决定是否信任它。
              </li>
              <div class="demo-image__placeholder">
                <div class="block">
                  <el-image :src="src" class="ai-robot"></el-image>
                </div>
              </div>
<!--              Details omitted for brevity-->
              <li>在最后的25项任务中，您仍然需要独立做出预测。</li>
            </ul>

            <p>
              整个过程大约需要40分钟。我们会根据您整个任务中的表现（决策准确率、注意力程度）支付您被试费。</p>
            <p style="color: red">请注意，我们将进行注意力检查。</p>
            <b>阅读完成后，请点击下面按钮，开始实验吧！</b><br>
            <!--            <router-link to="/tutorial" class="start-tutorial-btn">Start a tutorial</router-link>-->
            <el-button type="primary" round @click="nextPage"
                       style="display:block; margin: 0 auto; margin-top: 8%; border-radius: 3px; ">开始实验
            </el-button>
          </div>
        </div>

        <!-- page2 知情同意书-->
        <div v-show="concurrentPage === 2" id="questionnaire" class="tab-pane fade">
          <h3>知情同意书</h3>
          <p>在开始主要任务之前，我们需要征得您的同意。</p>
          <ul class="consent-intro">
            <li><strong>收集的数据：</strong>在本研究中，我们不会使用您的真实姓名和联系信息，我们将只收集您在任务界面上的交互日志和您对调查问卷中每个问题的回答。
            </li>
            <li><strong>自愿同意：</strong>参与本研究是自愿的。您可以随时退出或停止参与。
            </li>
          </ul>

          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="1.2">
              <div class="grid-content bg-purple" style="padding-right: 5px;">
                <el-button type="primary" plain v-on:click="directUserInfoPage">接受</el-button>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="grid-content bg-purple" style="padding-left: 5px;">
                <el-button type="info" :plain="true" @click="redirectAlert">拒绝</el-button>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- page3 基本信息-->
        <div v-show="3 === concurrentPage">
          <h3>请根据以下步骤，按顺序进行实验：</h3>
          <div class="Step-bar">
            <el-steps direction="vertical" :active="active" finish-status="success" simple style="margin-top: 20px">
              <el-step class="step1 cursor-pointer" title="步骤 1:问题调研" :description="$slots.default ? '' : '点击前往前测量表'"
                       @click.native="goTo('/scale')"
                       style="cursor: pointer;"></el-step>

              <el-step class="cursor-pointer" title="步骤 2:行为实验" :description="$slots.default ? '' : '点击前往行为实验'"
                       @click.native="goTo('/questionCollectionBeforeStudy')"
                       style="cursor: pointer;"></el-step>

              <el-step class="cursor-pointer" title="步骤 3:问题调研" :description="$slots.default ? '' : '点击前往行为实验'"
                       @click.native="goTo('/scaleCollectionAfter')"
                       style="cursor: pointer;"> </el-step>
            </el-steps>

            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="8">
                <div class="text-block">在该环节中，我们希望了解您对自身以及人工智能的看法，所有问题都将以李克特量表的形式呈现，请根据您的主观感受选择最适合您的选项，感谢您的参与。
                </div>
              </el-col>
              <el-col :span="8">
                <div class="text-block">在该环节中，你将看到75个人的个人资料, 包括：家庭背景特征、学习行为特征、学习表现特征。
                  您的任务是：预测一个学生某门课程的学习成绩（G3）。
                </div>
              </el-col>
              <el-col :span="8">
                <div class="text-block">在该环节中，我们希望收集您在实验任务中对AI的经验和看法的反馈。
                  所有问题都将以李克特量表的形式呈现，请根据您的主观感受选择最适合您的选项，感谢您的参与。
                </div>
              </el-col>
            </el-row>

          </div>

        </div>
<!--        <el-button @click="goToDirect('/questionCollection')">questionCollection测试页面</el-button>-->
<!--        <br>-->
<!--        <el-button @click="goToDirect('/questionCollectionAi')">questionCollectionAi测试页面</el-button>-->
<!--        <br>-->
<!--        <el-button @click="goToDirect('/questionCollectionBeforeStudy')">questionCollectionBeforeStudy测试页面</el-button>-->
<!--        <br>-->
<!--        <el-button @click="goToDirect('/questionCollectionAfterAi')">questionCollectionAfterAi测试页面</el-button>-->
<!--        <br>-->
<!--        <el-button @click="goToDirect('/scale')">测试页面</el-button>-->
<!--         <el-button @click="goToDirect('/demo')">测试页面</el-button>-->
<!--        <el-button @click="goToDirect('/resultShow')">resultShow测试页面</el-button>-->
<!--        <el-button @click="goToDirect('/thanks')">测试页面</el-button>-->

      </el-main>
     </el-container>
  </div>
</template>

<script>
export default {
  name: 'index-home',
  data() {
    return {
      concurrentPage: 1,
      active: 0,
      questionAnswerIsNull: true,
      scaleAnswerIsNull: true,
      userId: null,
      src:  require('@/img/AI-robot-detail.png')
    };
  },
  methods: {
    nextPage() {
      this.concurrentPage += 1;
    }, redirectAlert() {
      this.$message('感谢您的参与，您可选择关闭当前页面。');
    }, goTo(path) {
      if ("/scale" === path) {
        if (this.active !== 0) {
          const h = this.$createElement;
          this.$notify({
            title: '该步骤问卷已完成、或需要前置步骤问卷',
            duration: 1500,
            message: h('i', {style: 'color: teal'}, '1.5s后关闭该弹窗')
          });
        } else {
          this.$router.push(path);
        }
      } else if ("/questionCollectionBeforeStudy" === path) {
        if (this.active !== 1) {
          const h = this.$createElement;
          this.$notify({
            title: '该步骤问卷已完成、或需要前置步骤问卷',
            duration: 1500,
            message: h('i', {style: 'color: teal'}, '1.5s后关闭该弹窗')
          });
        } else {
          this.$router.push(path);
        }
      } else if ("/scaleCollectionAfter" === path) {
        if (this.active !== 2) {
          const h = this.$createElement;
          this.$notify({
            title: '该步骤问卷已完成、或需要前置步骤问卷',
            duration: 1500,
            message: h('i', {style: 'color: teal'}, '1.5s后关闭该弹窗')
          });
        } else {
          this.$router.push(path);
        }
      }
    }, goToDirect(path) {
      this.$router.push(path);
    }, directUserInfoPage() {
      const h = this.$createElement;
      this.$notify({
        title: '在实验开始之前，请您填写基础信息',
        duration: 2000,
        message: h('i', {style: 'color: teal'}, '2s 后跳转')
      });
      setTimeout(this.openIndex, 0);
    }, openIndex() {
      this.$router.push('/userInfo');
    }, get(key) {
      return sessionStorage.getItem(key);
    }, set(key, value) {
      sessionStorage.setItem(key, value);
    }
  },
  mounted() {
    let commonUserInfoJson = this.get('commonUserInfo');
    if (null != commonUserInfoJson) {
      let commonUserObj = JSON.parse(commonUserInfoJson);
      if (null != commonUserObj) {
        let userId = commonUserObj.userId;
        if (null != userId) {
          this.concurrentPage = 3;
          this.userId = userId;
        }
        if ("step0" === commonUserObj.step) {
          this.active = 0;
        } else if ("step1" === commonUserObj.step) {
          this.active = 1
        } else if ("step2" === commonUserObj.step) {
          this.active = 2
        } else {
          this.active = 3
        }
      }
    }
  }
}


</script>


<style>
/* Other styles omitted for brevity */
/*html, body {*/
/*  height: 100%;*/
/*  margin: 0;*/
/*}*/

body {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background: #f0f0f0; /* 可选的背景颜色 */
}

.container {
  width: 94vw; /* 视口宽度的75% */
  min-height: 100vh; /* 确保.el-container也铺满高度 */
  background-color: white; /* 容器背景颜色，根据需要调整 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* 可选的阴影效果 */
  overflow: auto; /* 如果内容超出容器大小，则允许滚动 */
}

/* Element UI容器样式调整 */
.el-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* 确保.el-container也铺满高度 */
}

.el-header, .el-footer {
  flex: 0 0 auto; /* 不允许header和footer伸缩 */
}

.el-main {
  flex: 1; /* 允许main区域填充剩余空间 */
  overflow: auto; /* 如果内容超出，则允许滚动 */
  margin-top: 5%; /* 或根据实际需要调整这个值 */
}

.page-content {
  /*background-color: #f4f4f9; !* 淡紫色背景 *!*/
  color: #333; /* 深灰色字体，提高可读性 */
  font-family: 'Arial', sans-serif; /* 现代简洁的字体 */
  padding: 0 14px;
  border-radius: 8px; /* 圆角边框 */
}

.welcome-section h3 {
  color: #5a67d8; /* 柔和的蓝色 */
  margin: 10px auto; /* 居中显示 */
}

.welcome-section ul {
  border: 2px solid #cccccc; /* 灰色边框 */
  /*background-color: #f0f0f0; !* 浅灰色背景 *!*/
  padding: 15px; /* 文本与边框的间距 */
  margin: 10px 0; /* 上下外边距 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 可选的轻微阴影效果 */
  border-radius: 1px; /* 可选的边框圆角 */
  list-style-position: inside; /* 确保序号在边框内部 */
  line-height: 24px;
}

.ai-robot {
  width: 400px; /* 调整图标大小 */
  display: block;
  margin: 10px auto; /* 居中显示 */
}

.welcome-section p {
  line-height: 1.6; /* 增加行间距，提高阅读舒适度 */
  margin-bottom: 10px; /* 段落间距 */
}

.welcome-section ul li {
  padding-left: 2em; /* 列表项内容和项目符号的缩进 */
}

.consent-intro {
  border: 2px solid #f0f0f0; /* 灰色边框 */
  background-color: white; /* 浅灰色背景 */
  padding: 10px; /* 文本与边框的间距 */
  margin: 10px 0; /* 上下外边距 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 可选的轻微阴影效果 */
  border-radius: 1px; /* 可选的边框圆角 */
  list-style-position: inside; /* 确保序号在边框内部 */
  line-height: 28px;
}

.custom-button .router-link-custom {
  text-decoration: none; /* 移除下划线 */
  font-family: '宋体', 'SimSun', sans-serif; /* 设置字体为宋体，黑体 */
  color: black; /* 文字颜色设置为黑色 */
}

.Step-bar {
  height: 300px;
}

.text-block {
  /*text-align: center; !* 文本居中 *!*/
  margin: 20px;
  line-height: 1.8;
  text-align: justify;
  /* 根据需要添加更多样式 */
}

/* 确保栅格内的内容均匀分布 */
.el-row {
  width: 100%; /* 占满屏幕宽度 */
}

.row-bg {
  margin: 0 auto; /* 如果需要，可调整外边距确保整体居中 */
}

.el-button:hover {
  background-color: #43a047; /* 鼠标悬停时的按钮颜色 */
}

.cursor-pointer {
  cursor: pointer;
}
</style>