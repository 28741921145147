<template>
  <div>
    <el-main>
      <el-button @click="initData()">XXXXXXXX</el-button>
    </el-main>
  </div>
</template>

<script>

export default {
  name: 'InformationAnalysis',
  beforeAiList: '',
  aiList: '',
  afterAiList: '',
  data() {
    return {}
  },
  created() {
  },
  methods: {
    initData() {
      // let temp1  = require('@/json/init_after_ai_question.json');
      // console.log("afterAiList",this.dealWithData(temp1));
      // let temp2  = require('@/json/init_ai_question.json');
      // console.log("aiList",this.dealWithData(temp2));
      // let temp3  = require('@/json/init_before_ai_question.json');
      // console.log("beforeAiList",this.dealWithData(temp3));
    },
    dealWithData(list) {
      list.forEach(i => {
            let dealWithDegree = this.dealWithDegree(JSON.parse(i.feature_impact_regression));

            i.feature_impact_regression = this.sortM(dealWithDegree)

            i.feature_impact_regression = this.renameFile(i.feature_impact_regression)

            i.feature_impact_regression = this.mergeArrObj2BigObj(i.feature_impact_regression)
          }
      )
      return list
    },
    renameFile(arr) {
      for (let item of arr) {
        for (let key in item) {
          let fn = '';
          if (key.includes('impact') && !key.includes('degree')) {
            fn = key;
            item[fn + '_sort'] = item.sort;
            delete item['sort'];
            delete item['degree'];
          }
        }
      }
      return arr;
    },
    mergeArrObj2BigObj(arr) {
      return arr.reduce((obj, item) => {
        return {...obj, ...item};
      }, {});
    },
    dealWithDegree(arr) {
      for (let item of arr) {
        for (let key in item) {
          if (key.includes('degree')) {
            item['degree'] = item[key];
          }
        }
      }
      return arr;
    },
    sortM(arr) {
      arr.sort((a, b) => b.degree - a.degree);
      for (let i = 0; i < arr.length; i++) {
        arr[i].sort = i+1;
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.content {
  width: 40%;
}

.info-box {
  border: 2px solid #ccc;
  background-color: #f2f2f2;
  padding: 10px;
  font-weight: bold;
}
</style>
