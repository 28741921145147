<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
        <el-alert type="info" :closable="false">
          <template v-slot:title>
            <p style="font-size: 16px; color: black">
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
          </template>
        </el-alert>
      </el-header>

      <el-main class="overflow-hid main-content">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)"
             :id="item.questionId">
          <div class="content">
            <p class="note">
              <strong>注意：</strong>请您认真作答。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="10">

              <el-tag class="base-intro"><strong>个人及家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item>
                    <template slot="label">父亲的教育程度（Fedu）</template>
                    {{ item.Fedu }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">母亲的教育程度（Medu）</template>
                    {{ item.Medu }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item>
                    <template slot="label">是否想接受高等教育（Highter）</template>
                    {{ item.higher }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">每周课余学习时间（studytime）</template>
                    <div style="display: flex; align-items: center;">
                      <el-progress :percentage="getPercentage(item.studytime)" :show-text="false" style="flex: 1; margin-right: 10px;"></el-progress>
                      <span>{{item.studytime}}</span>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">缺勤次数（absence）</template>
                    {{ item.absences }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item>
                    <template slot="label">历次课程未通过次数（failure）</template>
                    {{ item.failures }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">第一学期成绩（G1）</template>
<!--                    <span v-html="'&nbsp <strong>' + item.G1_pass + '</strong>考试 /  等级为： <strong>' +-->
<!--                    item.G1_class_A + '</strong> / 得分为：<strong>' +item.G1 + '</strong>分'">-->
<!--                    </span>-->
                    <span v-html="'<strong>' +item.G1 + '</strong> 分'">
                    </span>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">第二学期成绩（G2）</template>
<!--                    <span v-html="'&nbsp <strong>' + item.G2_pass + '</strong>考试 /  等级为： <strong>' +-->
<!--                    item.G2_class_A + '</strong> / 得分为：<strong>' +item.G2 + '</strong>分'">-->
<!--                    </span>-->
                    <span v-html="'<strong>' +item.G2 + '</strong> 分'">
                    </span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>

            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="13">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <div>
                  <span> 请您预测该学生的最终学习成绩（G3）：</span>
                  <br><br>
                  <el-cascader :options="options"
                               v-model="form.answers[item.questionId].score"
                               @change="recordSelectionTime(item.questionId,'score')"
                               :props="{ expandTrigger: 'hover' }">
                  </el-cascader>
                </div>
                <br>
                <div v-show="form.answers[item.questionId].score"
                     @click="recordSelectionTime(item.questionId,'confident')">
                  <span>我能够正确预测该学生的最终学习成绩</span>
                  <br><br>
                  <el-rate :max='7'
                           :texts='confidenceTextList'
                           show-text
                           void-color='#606266'
                           v-model="form.answers[item.questionId].confident">
                  </el-rate>
                </div>

                <br>
                <el-button type="primary" round v-on:click="nextQuestion(index)"
                           v-show="index < questionList.length - 1 && form.answers[item.questionId].confident">下一题
                </el-button>
                <el-button type="success" round v-on:click="goToQuestionCollectionPage()"
                           v-show="index === questionList.length - 1 && form.answers[item.questionId].confident">
                  该阶段测试结束，进入AI辅助答题页面
                </el-button>

              </div>
            </el-col>
          </el-row>
        </div>

      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  props: ['questionCollectionStudyForm', 'questionCollectionBeforeStudyForm'],
  name: "question-collection",
  mounted() {
    // console.log("当前页为测试页,上个页面的数据questionCollectionStudyForm", JSON.stringify(this.questionCollectionStudyForm));
    // console.log("当前页为测试页,上个页面的数据questionCollectionBeforeStudyForm", JSON.stringify(this.questionCollectionBeforeStudyForm));
  },
  created() {
    this.initializeData();
    this.form.pageLoadTime = this.getNowDate();
  },
  data() {
    return {
      confidenceTextList: ['非常不同意', '不同意', '比较不同意', '中立', '比较同意', '同意', '非常同意'],
      userId: null,
      showIndex: 0,
      form: {
        answers: {},
        pageLoadTime: null
      },
      options : [{"label":"分数区间:16-20","children":[{"value":"20","label":"分数为:20"},{"value":"19","label":"分数为:19"},{"value":"18","label":"分数为:18"},{"value":"17","label":"分数为:17"},{"value":"16","label":"分数为:16"}]},{"label":"分数区间:11-15","children":[{"value":"15","label":"分数为:15"},{"value":"14","label":"分数为:14"},{"value":"13","label":"分数为:13"},{"value":"12","label":"分数为:12"},{"value":"11","label":"分数为:11"}]},{"label":"分数区间:6-10","children":[{"value":"10","label":"分数为:10"},{"value":"9","label":"分数为:9"},{"value":"8","label":"分数为:8"},{"value":"7","label":"分数为:7"},{"value":"6","label":"分数为:6"}]},{"label":"分数区间:0-5","children":[{"value":"5","label":"分数为:5"},{"value":"4","label":"分数为:4"},{"value":"3","label":"分数为:3"},{"value":"2","label":"分数为:2"},{"value":"1","label":"分数为:1"},{"value":"0","label":"分数为:0"}]}],
      questionList: [],
    }
  }, methods: {
    getPercentage(str){
      if(str ==='') return 0;
      if(str === '小于2小时') return 30;
      if(str === '2 - 5小时') return 60;
      if(str === '5 - 10 小时') return 80;
      if(str === '大于10小时') return 100;
    },
    recordSelectionTime(questionId, recordType) {
      // 记录选项被选中的当前时间
      if ('score' === recordType) {
        this.form.answers[questionId].score =  this.form.answers[questionId].score[1];
        this.form.answers[questionId].scoreTime = this.getNowDate();
      }
      if ('confident' === recordType) {
        this.form.answers[questionId].confidentTime = this.getNowDate();
      }
      // console.log(this.form);
    },
    goToQuestionCollectionPage() {
      const h = this.$createElement;
      this.$notify({
        title: '问卷小测试已通过！',
        duration: 1500,
        message: h('i', {style: 'color: teal'}, '该阶段测试结束，进入AI辅助答题环节')
      });

      let arr = Object.entries(this.form.answers).map(([key, value]) => {
        return {id: key, ...value};
      });
      let paramData = {randomQuestion: this.randomQuestion, pageLoadTime: this.form.pageLoadTime, answers: arr};

      this.$router.push({
        name: 'questionCollectionAi',
        params: {
          questionCollectionBeforeStudyForm: this.questionCollectionBeforeStudyForm,
          questionCollectionStudyForm: this.questionCollectionStudyForm,
          questionCollectionForm: paramData,
        }
      });
    },
    initializeData() {
      let before_ai = require('@/json/init_before_ai_question.json');
      let ai = require('@/json/init_ai_question.json');
      let after_ai = require('@/json/init_after_ai_question.json');
      this.questionList = [before_ai, ai, after_ai][JSON.parse(this.get('commonUserInfo')).randomQuestion.indexOf('before')];
      this.questionList.forEach(table => {
        // 初始化答案结构，包括选中值和时间
        this.$set(this.form.answers, table.questionId,
            {
              id: table.ID,
              standAnswer: table.G3,
              score: null,
              scoreTime: null,
              confident: null,
              confidentTime: null,
            }
        );
      });
    }, whetherShow(index) {
      return this.showIndex === index;
    }, nextQuestion(index) {
      this.showIndex = index + 1;
      this.showAnswerCtrl = false;
    }, get(key) {
      return sessionStorage.getItem(key);
    }, set(key, value) {
      sessionStorage.setItem(key, value);
    }, getNowDate() {
      let date = new Date();
      let sign2 = ":";
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let minutes = date.getMinutes(); // 分
      let seconds = date.getSeconds() //秒
      // let weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      // let week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    }

  }
}
</script>

<style scoped>

.home-container {
  min-height: 100vh;
  height: 100%;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 8px;
}


.base-intro {
  margin: 8px 0px;
  font-size: 14px;
}

.content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}


.note {
  background-color: #ffefd5; /* 浅黄色背景 */
  padding: 10px;
  border-left: 3px solid #ffa07a; /* 浅橙色边框 */
  margin: 6px 0;
}

.note strong {
  color: #d2691e; /* 加深的橙色文字，增加注意力 */
}

.question {
  margin-top: 16px;
  padding-top: 10px; /* 设置顶部内边距 */
  padding-right: 25px; /* 设置右侧内边距 */
  padding-bottom: 20px; /* 设置底部内边距 */
  padding-left: 25px; /* 设置左侧内边距 */
}

.main-q {
  margin-top: 6px;
  margin-bottom: 6px;
}

.text-block {
  /*text-align: center; !* 文本居中 *!*/
  margin: 20px 0px;
  /* 根据需要添加更多样式 */
  /*padding: 50px 0px;*/
}

.center {
  text-align: center;
}
</style>