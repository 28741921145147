<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
      </el-header>
      <el-main class="overflow-hid" style="margin: 0;padding: 0 20px 20px 20px;">
        <el-container>
          <div class="content">
            <el-col :span="21">
              <p class="note">
                <strong>注意：</strong>
                下面展示的是您在学生成绩预测任务上的整体表现情况。请仔细阅读后，<strong>点击右侧的 “返回首页” 按钮</strong>
              </p>
            </el-col>
<!--            <el-col :span="1"></el-col>-->
            <el-col :span="2" style="margin-top: 16px; margin-left: 16px;">
                <el-button @click="goToDirect('/index')" style="background: yellowgreen;">返回首页</el-button>
            </el-col>
          </div>
        </el-container>
          <div>
            <el-row>
              <el-col :span="16">
                <div id="myChart1" :style="{width: '100%', height: '340px'}"></div>
              </el-col>
              <el-col :span="8">
                <div class="chart_right">
                  <p>
                    <strong>您在第一部分的答题效果如下：</strong><br>
                    平均误差为：<strong>{{ formattedAverageError }}</strong><br>
<!--                    a（a=[绝对值(this.QCValueData-this.QCStandAnswerData)]13个题累加，除以13)<br>-->
                    没有误差的题目数量为：<strong>{{ countErrorWithin0 }}</strong><br>
<!--                    n1 (n=if 绝对值(data: this.QCValueData-this.QCStandAnswerData) 小于 5, n+1)<br>-->
                    误差为1分的题目数量为：<strong>{{ countErrorWithin1 }}</strong><br>
<!--                    n2 (n=if 绝对值(data: this.QCValueData-this.QCStandAnswerData) 小于 10, n+1)<br>-->
                    误差为2分的题目数量为：<strong>{{ countErrorAbove2 }}</strong><br>
<!--                    n2 (n=if 绝对值(data: this.QCValueData-this.QCStandAnswerData) 大于 10, n+1)<br>&ndash;&gt;-->
                    误差为3分及以上的题目数量为：<strong>{{ countErrorAbove3 }}</strong>
                    <!--                    n2 (n=if 绝对值(data: this.QCValueData-this.QCStandAnswerData) 大于 10, n+1)<br>&ndash;&gt;-->
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-divider></el-divider><br>

            <el-row>
              <el-col :span="16">
                <div id="myChart2" :style="{width: '100%', height: '340px'}"></div>
              </el-col>
              <el-col :span="8">
                <div class="chart_right">
                  <p>
                    <strong>您在第二部分的答题效果如下：</strong><br>
                    平均误差为：<strong>{{ formattedAverageErrorAi }}</strong><br>
                    <!--                    a（a=[绝对值(this.QCAIStandAnswerData-this.QCAIValue2Data)]13个题累加，除以13)<br>-->
                    没有误差的题目数量为：<strong>{{ countErrorWithin0Ai }}</strong><br>
                    <!--                    n1 (n=if 绝对值(data: this.QCAIStandAnswerData-this.QCAIValue2Data) 小于 5, n+1)<br>-->
                    误差为1分的题目数量为：<strong>{{ countErrorWithin1Ai }}</strong><br>
                    <!--                    n2 (n=if 绝对值(data: this.QCAIStandAnswerData-this.QCAIValue2Data) 小于 10, n+1)<br>-->
                    误差为2分的题目数量为：<strong>{{ countErrorAbove2Ai }}</strong><br>
                    <!--                    n2 (n=if 绝对值(data: this.QCAIStandAnswerData-this.QCAIValue2Data) 大于 10, n+1)<br>&ndash;&gt;-->
                    误差为3分及以上的题目数量为：<strong>{{ countErrorAbove3Ai }}</strong>
                    <!--                    n2 (n=if 绝对值(data: this.QCAIStandAnswerData-this.QCAIValue2Data) 大于 10, n+1)<br>&ndash;&gt;-->
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-divider></el-divider><br>

            <el-row>
              <el-col :span="16">
                <div id="myChart3" :style="{width: '100%', height: '340px'}"></div>
              </el-col>
              <el-col :span="8">
                <div class="chart_right">
                  <p>
                    <strong>您在第三部分的答题效果如下：</strong><br>
                    平均误差为：<strong>{{ formattedAverageErrorAiAfter }}</strong><br>
                    <!--                    a（a=[绝对值(this.QCAiAfterValueData-this.QCAiAfterStandAnswerData)]13个题累加，除以13)<br>-->
                    没有误差的题目数量为：<strong>{{ countErrorWithin0AiAfter }}</strong><br>
                    <!--                    n1 (n=if 绝对值(data: this.QCAiAfterValueData-this.QCAiAfterStandAnswerData) 小于 5, n+1)<br>-->
                    误差为1分的题目数量为：<strong>{{ countErrorWithin1AiAfter }}</strong><br>
                    <!--                    n2 (n=if 绝对值(data: this.QCAiAfterValueData-this.QCAiAfterStandAnswerData) 小于 10, n+1)<br>-->
                    误差为2分的题目数量为：<strong>{{ countErrorAbove2AiAfter }}</strong><br>
                    <!--                    n2 (n=if 绝对值(data: this.QCAiAfterValueData-this.QCAiAfterStandAnswerData) 大于 10, n+1)<br>&ndash;&gt;-->
                    误差为3分及以上的题目数量为：<strong>{{ countErrorAbove3AiAfter }}</strong>
                    <!--                    n2 (n=if 绝对值(data: this.QCAiAfterValueData-this.QCAiAfterStandAnswerData) 大于 10, n+1)<br>&ndash;&gt;-->
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-divider></el-divider><br>
          </div>

      </el-main>
    </el-container>
  </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'resultShow',
  props: ['echartsQuestionCollectionAiAfterAnswer', 'echartsQuestionCollectionAiAnswer', 'echartsQuestionCollectionAnswer'],
  data() {
    return {

      // 我的答案
      QCValueData: [],
      // 标准答案
      QCStandAnswerData: [],

      // 我的答案1
      QCAIValueData: [],
      // 我的答案2
      QCAIValue2Data: [],
      // AI预测
      QCAIAiAnswerData: [],
      // 标准答案
      QCAIStandAnswerData: [],

      // 我的答案
      QCAiAfterValueData: [],
      // 标准答案
      QCAiAfterStandAnswerData: [],

      // xAxis X坐标 由数据条数生成
      myEcharts1xAxisData: [],
      myEcharts2xAxisData: [],
      myEcharts3xAxisData: [],

      // 自测时放开
      // echartsQuestionCollectionAnswer: [],
      // echartsQuestionCollectionAiAnswer: [],
      // echartsQuestionCollectionAiAfterAnswer: [],
    };
  },
  mounted() {
    this.initData();
    this.drawLine();
  },
  methods: {
    initData(){
      // 自测时放开，放置对应数据
      // this.echartsQuestionCollectionAnswer=  {"pageLoadTime":"2024-06-29 21:14:31","answers":[{"id":895,"standAnswer":19,"score":"20"},{"id":896,"standAnswer":2,"score":2},{"id":897,"standAnswer":8,"score":4},{"id":898,"standAnswer":2,"score":0},{"id":899,"standAnswer":10,"score":5},{"id":900,"standAnswer":11,"score":0},{"id":901,"standAnswer":14,"score":15},{"id":902,"standAnswer":1,"score":2},{"id":903,"standAnswer":13,"score":3},{"id":904,"standAnswer":6,"score":3},{"id":905,"standAnswer":6,"score":3},{"id":906,"standAnswer":13,"score":4},{"id":907,"standAnswer":4,"score":5},{"id":908,"standAnswer":7,"score":6},{"id":909,"standAnswer":2,"score":0},{"id":910,"standAnswer":14,"score":3},{"id":911,"standAnswer":7,"score":2},{"id":912,"standAnswer":9,"score":3},{"id":913,"standAnswer":2,"score":1},{"id":914,"standAnswer":10,"score":11},{"id":915,"standAnswer":8,"score":9},{"id":916,"standAnswer":6,"score":2},{"id":917,"standAnswer":9,"score":11},{"id":918,"standAnswer":4,"score":2},{"id":919,"standAnswer":2,"score":0}]};
      // this.echartsQuestionCollectionAiAnswer=  {"pageLoadTime":"2024-06-29 21:14:48","answers":[{"id":895,"standAnswer":19,"score":"20"},{"id":896,"standAnswer":2,"score":2},{"id":897,"standAnswer":8,"score":4},{"id":898,"standAnswer":2,"score":0},{"id":899,"standAnswer":10,"score":5},{"id":900,"standAnswer":11,"score":0},{"id":901,"standAnswer":14,"score":15},{"id":902,"standAnswer":1,"score":2},{"id":903,"standAnswer":13,"score":3},{"id":904,"standAnswer":6,"score":3},{"id":905,"standAnswer":6,"score":3},{"id":906,"standAnswer":13,"score":4},{"id":907,"standAnswer":4,"score":5},{"id":908,"standAnswer":7,"score":6},{"id":909,"standAnswer":2,"score":0},{"id":910,"standAnswer":14,"score":3},{"id":911,"standAnswer":7,"score":2},{"id":912,"standAnswer":9,"score":3},{"id":913,"standAnswer":2,"score":1},{"id":914,"standAnswer":10,"score":11},{"id":915,"standAnswer":8,"score":9},{"id":916,"standAnswer":6,"score":2},{"id":917,"standAnswer":9,"score":11},{"id":918,"standAnswer":4,"score":2},{"id":919,"standAnswer":2,"score":0}]};
      // this.echartsQuestionCollectionAiAfterAnswer=  {"pageLoadTime":"2024-06-29 21:15:09","answers":[{"id":895,"standAnswer":19,"score":"20"},{"id":896,"standAnswer":2,"score":2},{"id":897,"standAnswer":8,"score":4},{"id":898,"standAnswer":2,"score":0},{"id":899,"standAnswer":10,"score":5},{"id":900,"standAnswer":11,"score":0},{"id":901,"standAnswer":14,"score":15},{"id":902,"standAnswer":1,"score":2},{"id":903,"standAnswer":13,"score":3},{"id":904,"standAnswer":6,"score":3},{"id":905,"standAnswer":6,"score":3},{"id":906,"standAnswer":13,"score":4},{"id":907,"standAnswer":4,"score":5},{"id":908,"standAnswer":7,"score":6},{"id":909,"standAnswer":2,"score":0},{"id":910,"standAnswer":14,"score":3},{"id":911,"standAnswer":7,"score":2},{"id":912,"standAnswer":9,"score":3},{"id":913,"standAnswer":2,"score":1},{"id":914,"standAnswer":10,"score":11},{"id":915,"standAnswer":8,"score":9},{"id":916,"standAnswer":6,"score":2},{"id":917,"standAnswer":9,"score":11},{"id":918,"standAnswer":4,"score":2},{"id":919,"standAnswer":2,"score":0}]};
      // console.log("echartsQuestionCollectionAnswer", JSON.stringify(this.echartsQuestionCollectionAnswer));
      // console.log("echartsQuestionCollectionAiAnswer", JSON.stringify(this.echartsQuestionCollectionAiAnswer));
      // console.log("echartsQuestionCollectionAiAfterAnswer", JSON.stringify(this.echartsQuestionCollectionAiAfterAnswer));

      for (let i = 0; i < this.echartsQuestionCollectionAnswer.answers.length; i++){
        const a = this.echartsQuestionCollectionAnswer.answers[i];
        this.QCValueData.push(Number(a.score));
        this.QCStandAnswerData.push(Number(a.standAnswer));
        this.myEcharts1xAxisData.push('第'+(i+1)+'题');
      }
      for (let i = 0; i < this.echartsQuestionCollectionAiAnswer.answers.length; i++){
        const a = this.echartsQuestionCollectionAiAnswer.answers[i];
        this.QCAIValue2Data.push(Number(a.reScore == null ? a.score : a.reScore));
        this.QCAIStandAnswerData.push(Number(a.standAnswer));
        this.myEcharts2xAxisData.push('第'+(i+1)+'题');
      }

      for (let i = 0; i < this.echartsQuestionCollectionAiAfterAnswer.answers.length; i++){
        const a = this.echartsQuestionCollectionAiAfterAnswer.answers[i];
        this.QCAiAfterValueData.push(Number(a.score));
        this.QCAiAfterStandAnswerData.push(Number(a.standAnswer));
        this.myEcharts3xAxisData.push('第'+(i+1)+'题');
      }
    },
    goToDirect(path) {
      this.$router.push(path);
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = echarts.init(document.getElementById('myChart1'))
      let myChart2 = echarts.init(document.getElementById('myChart2'))
      let myChart3 = echarts.init(document.getElementById('myChart3'))
      // 绘制图表
      myChart1.setOption({
        color: ["#ff004d","#387a29"],
        title: {
          text: '第一部分：自主答题--前25个题目',
          // left: 'center',
          top: '10', },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        }, toolbox: {
          show: true,
          feature: {
            restore: {},
            magicType: {type: ['line', 'bar']},
          }
        },
        legend: {
          data: ['我的答案','标准答案'],
          top: '40'  // 将图例向下移动，或设置为 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '70',  // 增加网格的顶部边距
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.myEcharts1xAxisData
        },
        yAxis: {
          type: 'value',
          min: 0,  // y轴最小值
          max: 20,  // y轴最大值
          interval: 10,  // y轴间隔
          axisLabel: {
            formatter: '{value} 分'  // 显示格式，后面加“分”
          }
        },
        series: [
          {
            name: '我的答案',
            type: 'line',
            data: this.QCValueData
          },{
            name: '标准答案',
            type: 'line',
            data: this.QCStandAnswerData
          }
        ]
      });
      myChart2.setOption({
        color: ["#ff004d","#387a29"],
        title: {
          text: '第二部分：AI辅助答题--中间25个题',
          // left: 'center',
          top: '10',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        }, toolbox: {
          show: true,
          feature: {
            restore: {},
            magicType: {type: ['line', 'bar']},
          }
        },
        legend: {
          // data: ['第一次回答','第二次回答','AI预测答案','标准答案']
          data: ['我的答案','标准答案'],
          top: '40'  // 将图例向下移动，或设置为 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '70',  // 增加网格的顶部边距
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.myEcharts2xAxisData
        },
        yAxis: {
          type: 'value',
          min: 0,  // y轴最小值
          max: 20,  // y轴最大值
          interval: 10,  // y轴间隔
          axisLabel: {
            formatter: '{value} 分'  // 显示格式，后面加“分”
          }
        },
        series: [
          // {
          //   name: '第一次回答',
          //   type: 'line',
          //   data: this.QCAIValueData
          // },
          {
            name: '我的答案',
            type: 'line',
            data: this.QCAIValue2Data
          },
          // {
          //   name: 'AI预测答案',
          //   type: 'line',
          //   data: this.QCAIAiAnswerData
          // },
          {
            name: '标准答案',
            type: 'line',
            data: this.QCAIStandAnswerData
          }]
      });
      myChart3.setOption({
        color: ["#ff004d","#387a29"],
        title: {
          text: '第三部分：自主答题--后25个题',
          // left: 'center',
          top: '10',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        }, toolbox: {
          show: true,
          feature: {
            restore: {},
            magicType: {type: ['line', 'bar']},
          }
        },
        legend: {
          data: ['我的答案','标准答案'],
          top: '40'  // 将图例向下移动，或设置为 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '70',  // 增加网格的顶部边距
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.myEcharts3xAxisData
        },
        yAxis: {
          type: 'value',
          min: 0,  // y轴最小值
          max: 20,  // y轴最大值
          interval: 10,  // y轴间隔
          axisLabel: {
            formatter: '{value} 分'  // 显示格式，后面加“分”
          }
        },
        series: [
          {
            name: '我的答案',
            type: 'line',
            data: this.QCAiAfterValueData
          },{
            name: '标准答案',
            type: 'line',
            data: this.QCAiAfterStandAnswerData
          }
        ]
      });
    }
  },
  computed: {
    averageError() {
      const diffs = this.QCValueData.map((value, index) =>
          Math.abs(value - this.QCStandAnswerData[index])
      );
      const sum = diffs.reduce((sum, val) => sum + val, 0);
      return sum / (this.QCValueData.length || 1); // Avoid division by zero
    },
    formattedAverageError() {
      return this.averageError.toFixed(2);
    },
    countErrorWithin0() {
      return this.QCValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCStandAnswerData[index]) === 0 ? 1 : 0), 0
      );
    },
    countErrorWithin1() {
      return this.QCValueData.reduce((count, value, index) =>
              count + (
                  Math.abs(value - this.QCStandAnswerData[index]) === 1 ? 1 : 0
              ), 0
      );
    },
    countErrorAbove2() {
      return this.QCValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCStandAnswerData[index]) === 2 ? 1 : 0), 0
      );
    },
    countErrorAbove3() {
      return this.QCValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCStandAnswerData[index]) >= 3 ? 1 : 0), 0
      );
    },
    averageErrorAi() {
      const diffs = this.QCAIValue2Data.map((value, index) =>
          Math.abs(value - this.QCAIStandAnswerData[index])
      );
      const sum = diffs.reduce((sum, val) => sum + val, 0);
      return sum / (this.QCAIValue2Data.length || 1);
    },
    formattedAverageErrorAi() {
      return this.averageErrorAi.toFixed(2);
    },
    countErrorWithin0Ai() {
      return this.QCAIValue2Data.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAIStandAnswerData[index]) === 0 ? 1 : 0), 0
      );
    },
    countErrorWithin1Ai() {
      return this.QCAIValue2Data.reduce((count, value, index) =>
              count + (
                  Math.abs(value - this.QCAIStandAnswerData[index]) === 1 ? 1 : 0
              ), 0
      );
    },
    countErrorAbove2Ai() {
      return this.QCAIValue2Data.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAIStandAnswerData[index]) === 2 ? 1 : 0), 0
      );
    },
    countErrorAbove3Ai() {
      return this.QCAIValue2Data.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAIStandAnswerData[index]) >= 3 ? 1 : 0), 0
      );
    },
    averageErrorAiAfter() {
      const diffs = this.QCAiAfterValueData.map((value, index) =>
          Math.abs(value - this.QCAiAfterStandAnswerData[index])
      );
      const sum = diffs.reduce((sum, val) => sum + val, 0);
      return sum / (this.QCAiAfterValueData.length || 1);
    },
    formattedAverageErrorAiAfter() {
      return this.averageErrorAiAfter.toFixed(2);
    },
    countErrorWithin0AiAfter() {
      return this.QCAiAfterValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAiAfterStandAnswerData[index]) === 0 ? 1 : 0), 0
      );
    },
    countErrorWithin1AiAfter() {
      return this.QCAiAfterValueData.reduce((count, value, index) =>
              count + (
                  Math.abs(value - this.QCAiAfterStandAnswerData[index]) === 1 ? 1 : 0
              ), 0
      );
    },
    countErrorAbove2AiAfter() {
      return this.QCAiAfterValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAiAfterStandAnswerData[index]) === 2 ? 1 : 0), 0
      );
    },
    countErrorAbove3AiAfter() {
      return this.QCAiAfterValueData.reduce((count, value, index) =>
          count + (Math.abs(value - this.QCAiAfterStandAnswerData[index]) >= 3 ? 1 : 0), 0
      );
    }
  }
};
</script>

<style scoped>
.note {
  background-color: #ffefd5; /* 浅黄色背景 */
  padding: 10px;
  border-left: 3px solid #ffa07a; /* 浅橙色边框 */
}

.chart_right{
  margin-top: 20%;
  margin-right: 10%;
  padding: 0 10% 4% 4%;
  line-height: 30px; /* 调整行高以居中文本 */
  text-align: justify;
  height: 220px;
  border: 2px solid #ffa07a; /* 蓝色边框 */
  background-color: #ffefd5; /* 浅黄色背景 */
  border-radius: 10px; /* 圆角边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}
</style>