import Vue from 'vue'
import Router from 'vue-router'
import indexHome from '@/components/index-home.vue'
import scale from "@/components/scale-collection.vue";
import questionCollection from "@/components/question-collection.vue";
import userInfo from "@/components/UserInfo.vue";
import scaleCollectionAfter from "@/components/scale-collection-after.vue";
import demo from "@/components/demo.vue"
import questionCollectionStudy from "@/components/question-collection-study.vue";
import questionCollectionAi from "@/components/question-collection-ai.vue";
import questionCollectionAfterAi from "@/components/question-collection-after-ai.vue";
import questionCollectionBeforeStudy from "@/components/question-collection-before-study.vue";
import resultShow from "@/components/echarts-result-show.vue";
import thanks from "@/components/thanks.vue";

Vue.use(Router)

export default new Router({
    routes: [
        {path: '/', redirect: '/index'},
        {path: '/index', name: 'index', component: indexHome, props: true},
        {path: '/scale', name: 'scaleCollection', component: scale, props: true},
        {path: '/userInfo', component: userInfo},
        {path: '/scaleCollectionAfter', name: 'scaleCollectionAfter', component: scaleCollectionAfter},
        {path: '/demo', component: demo},
        {path: '/thanks', component: thanks},
        {path: '/resultShow', name: 'resultShow', component: resultShow , props: true},
        {
            path: '/questionCollectionStudy',
            name: 'questionCollectionStudy',
            component: questionCollectionStudy,
            props: true
        },
        {path: '/questionCollection', name: 'questionCollection', component: questionCollection, props: true},
        {path: '/questionCollectionAi', name: 'questionCollectionAi', component: questionCollectionAi, props: true},
        {
            path: '/questionCollectionAfterAi',
            name: 'questionCollectionAfterAi',
            component: questionCollectionAfterAi,
            props: true
        },
        {
            path: '/questionCollectionBeforeStudy',
            name: 'questionCollectionBeforeStudy',
            component: questionCollectionBeforeStudy,
            props: true
        },
    ],
    mode: 'history'
})
