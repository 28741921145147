<template>
  <div class="container">
    <div class="thank">
      <p class="thank_font">
        <strong>您已经完成实验，谢谢您的参与！</strong>
      </p>
      <p class="subtext">
        <strong>祝您开开心心，万事胜意！</strong>
      </p>
      </div>
      </div>
</template>

<script>
export default {
  name: 'thanks-page',
  data() {
    return {}
  }
}
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  background-image: url('../img/img.png');
  background-size: cover; /* 使背景图像覆盖整个容器 */
  background-position: center; /* 使背景图像在容器中居中 */
  background-repeat: no-repeat; /* 防止背景图像重复 */
}


.thank {
  height: 220px;
  width: 100%;
  background: #33ccff;
  margin-top: 3%;
  padding: 20px;
  text-align: center;
  background: rgba(51, 204, 255, 0.9); /* 使用半透明背景色 */
}

.thank_font {
  font-size: 36px;
  color: black;
  line-height: 1.6;
}

.subtext {
  font-size: 26px;
  color: black;
  margin-top: 10px;
}
</style>