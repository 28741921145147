<script setup>

</script>

<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
        <el-alert type="info" :closable="false">
          <template v-slot:title>
            <p style="font-size: 16px; color: black">
              <strong>注意:</strong> 在整个实验过程中，请 <b style="color: darkred;">不要</b> 刷新浏览器。否则将丢失所有数据。
            </p>
          </template>
        </el-alert>
      </el-header>
      <el-main class="overflow-hid">
        <el-tag class="base-intro">
          一、对于每个任务，您将看到3个表格: 《家庭背景特征》、《学习行为特征》、《学习表现特征》
        </el-tag>
        <el-container>

          <p><strong>1.《家庭背景特征》：</strong>显示了每个学生的家庭背景信息，共有2个属性，我们列出了每个属性的所有可能值:</p>
          <div style="border: 1px dashed black;">
            <el-row>
              <el-col :span="5">
                <div class="bg-purple">属性</div>
              </el-col>
              <el-col :span="19">
                <div class="bg-purple">值</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>父亲的教育程度（Fedu）</div>
              </el-col>
              <el-col :span="19" class="text-container">
                <p>类别 ( 文盲、
                  基础教育（1-4年级）、
                  基础教育（5-9年级）、
                  中等教育（高中）、
                  高等教育（大学） )</p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>母亲的教育程度（Medu）</div>
              </el-col>
              <el-col :span="19" class="text-container">
                <p>类别 ( 文盲、
                  基础教育（1-4年级）、
                  基础教育（5-9年级）、
                  中等教育（高中）、
                  高等教育（大学） )</p>
              </el-col>
            </el-row>
          </div>

          <p><strong>2.《学习行为特征》：</strong>显示了每个学生的学习行为，共有3个属性，我们列出了每个属性的所有可能值:</p>
          <div style="border: 1px dashed black;">
            <el-row>
              <el-col :span="5">
                <div class="bg-purple">属性</div>
              </el-col>
              <el-col :span="19">
                <div class="bg-purple">值</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>是否希望接受高等教育（highter）</div>
              </el-col>
              <el-col :span="19" class="text-container" >
                <p>类别（是、否）</p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>每周课余学习时间（studytime）</div>
              </el-col>
              <el-col :span="19" class="text-container" >
                <p>类别（小于2小时，2 - 5小时，5 - 10小时、大于10小时）</p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>上课缺勤次数（absence）</div>
              </el-col>
              <el-col :span="19" class="text-container" >
                <p>次数：0-93次</p>
              </el-col>
            </el-row>

          </div>

          <p><strong>3.《学习表现特征》：</strong>显示了每个学生某门课程的考试成绩（<strong>一学年共有3个学期</strong>），共有3个属性，我们列出了每个属性的所有可能值:</p>
          <div style="border: 1px dashed black;">
            <el-row>
              <el-col :span="5">
                <div class="bg-purple">属性</div>
              </el-col>
              <el-col :span="19">
                <div class="bg-purple">值</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri bg-purple">
                <div>历次考试未通过次数（failure）</div>
              </el-col>
              <el-col :span="19" class="text-container" >
                <p>次数：0-3次，大于3次统一用4表示</p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" class="atri bg-purple">
                <div>第一学期成绩（G1）</div>
              </el-col>
              <el-col :span="19" class="text-container">
<!--                <p><strong>1.成绩是否通过：</strong>通过、未通过 // <strong>2.成绩等级：</strong>A（16-20分）、B（14-15分）、C（12-13分）、D（10-11分）、F（0-9分）// <strong>3.分数：</strong>20分制-->
<!--                </p>-->
                <p><strong>分数：</strong>20分制
                </p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" class="atri bg-purple">
                <div>第二学期成绩（G2）</div>
              </el-col>
              <el-col :span="19" class="text-container">
                <p><strong>分数：</strong>20分制
                </p>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" class="atri bg-purple">
                <div>最终学习成绩（G3）</div>
              </el-col>
              <el-col :span="19" class="text-container">
                <p><strong>分数：</strong>20分制
                </p>
              </el-col>
            </el-row>
          </div>
        </el-container>
        <br>
        <el-tag class="base-intro">
          二、为了帮助您了解任务，我们在此为您提供了每个属性组对应的学生最终学习成绩分布。
        </el-tag>
        <br><br>
        <el-container>
          <div style="border: 1px dashed black;">
            <el-row>
              <el-col :span="5">
                <div class="bg-purple">属性</div>
              </el-col>
              <el-col :span="19">
                <div class="bg-purple">值</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>父亲的教育程度（Fedu）</div>
              </el-col>
              <el-col :span="19" class="image-container">
                <el-image :src="src1" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>母亲的教育程度（Medu）</div>
              </el-col>
              <el-col :span="19" class="image-container">
                <el-image :src="src2" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>是否希望接受高等教育（highter）</div>
              </el-col>
              <el-col :span="19" class="image-container" >
                <el-image :src="src3" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>每周课余学习时间（studytime）</div>
              </el-col>
              <el-col :span="19" class="image-container" >
                <el-image :src="src4" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>上课缺勤次数（absence）</div>
              </el-col>
              <el-col :span="19" class="image-container" >
                <el-image :src="src5" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5"  class="atri-feature bg-purple">
                <div>历次考试未通过次数（failure）</div>
              </el-col>
              <el-col :span="19" class="image-container" >
                <el-image :src="src6" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" class="atri-feature bg-purple">
                <div>第一学期成绩（G1）</div>
              </el-col>
              <el-col :span="19" class="image-container">
                <el-image :src="src7" class="ai-feature"></el-image>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" class="atri-feature bg-purple">
                <div>第二学期成绩（G2）</div>
              </el-col>
              <el-col :span="19" class="image-container">
                <el-image :src="src8" class="ai-feature"></el-image>
              </el-col>
            </el-row>

          </div>

        </el-container>

        <br>
        <el-form ref="form" :model="form" label-width="500px">
          <el-tag type="success" class="base-intro">三、该任务的目的是什么？</el-tag>
          <br><br>
<!--          <el-form-item label="该任务的目的是什么？" label-width="10"><br>-->
          <el-radio-group v-model="form.answers.value1" @change="recordSelectionTime('value1time')">
            <el-radio label="预测学生的学习行为"></el-radio>
            <el-radio label="预测学生的学习成绩"></el-radio>
            <el-radio label="预测学生的学习时间"></el-radio>
          </el-radio-group>
          <br><br>

          <el-tag type="success" class="base-intro">四、哪一组人更容易获得最高的最终成绩？</el-tag>
          <br><br>
          <!--          <el-form-item label="该任务的目的是什么？" label-width="10"><br>-->
          <el-radio-group v-model="form.answers.value2" @change="recordSelectionTime()">
            <el-radio label="父母教育程度低，上课缺勤次数多，每周学习时间短"></el-radio>
            <el-radio label="父母教育程度高，上课缺勤次数多，每周学习时间短"></el-radio>
            <el-radio label="父母教育程度高，上课缺勤次数少，每周学习时间长"></el-radio>
          </el-radio-group>
          <br>
          <el-form-item style="margin-top: 30px">
            <el-button type="primary" @click="onSubmit">立即开始</el-button>
          </el-form-item>
        </el-form>

      </el-main>
    </el-container>
  </div>
</template>

<script>
import imgGender from '@/img/gender.png';
import imgAge from '@/img/age.png';
import imgEdu from '@/img/edu.png';
import imgClassP from '@/img/classP.png';
export default {
  name: "question-collection-before-study",
  data() {
    return {
      src1:  require('@/img/Fedu.png'),
      src2:  require('@/img/Medu.png'),
      src3:  require('@/img/Highter.png'),
      src4:  require('@/img/studytime.png'),
      src5:  require('@/img/absence.png'),
      src6:  require('@/img/failure.png'),
      src7:  require('@/img/G1.png'),
      src8:  require('@/img/G2.png'),
      srcGender: imgGender,
      srcAge: imgAge,
      srcEdu: imgEdu,
      srcClassP: imgClassP,
      form: {
        answers: {value1: null, time1: null, value2: null, time2: null},
        pageLoadTime: null
      }
    }
  }, created() {
    this.form.pageLoadTime = this.getNowDate();
  },
  methods: {
    onSubmit() {
      const h = this.$createElement;
      this.$notify({
        title: '问卷了解页面！',
        duration: 1500,
        message: h('i', {style: 'color: teal'}, '转到正式测试学习页面')
      });
      this.$router.push({
        name: 'questionCollectionStudy',
        params: {questionCollectionBeforeStudyForm: this.form}
      });
    }, recordSelectionTime(type) {
      if (type === 'value1time') {
        this.form.answers.time1 = this.getNowDate();
      } else {
        this.form.answers.time2 = this.getNowDate();
      }
    }, getNowDate() {
      let date = new Date();
      let sign2 = ":";
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let minutes = date.getMinutes(); // 分
      let seconds = date.getSeconds() //秒
      // let weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      // let week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    }
  }
}
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  height: 100%;
}

.bg-purple {
  background: dimgray;
  /*background: #cccccc;*/
  padding: 6px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: center; /* 水平居中文本 */
  margin-bottom: 1px;
  /*height: 40px; !* 确保容器高度足够，根据需要调整 *!*/
}

.text-container {
  text-align: center; /* 水平居中文本 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 38px; /* 确保容器高度足够，根据需要调整 */
  border: black 1px;
  font-size: 14px;
}
.image-container {
  text-align: center; /* 水平居中文本 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  /*height: 300%; !* 确保容器高度足够，根据需要调整 *!*/
  border: black 1px;
  font-size: 16px;
  height: 300px; /* 调整为所需的高度 */
}
.atri{
  height: 38px;
  text-align: center; /* 水平居中文本 */
  background: dimgray;
}
.atri-feature{
  display: flex; /* 启用flex布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 文本水平居中 */
  background: dimgray; /* 背景颜色 */
  height: 300px; /* 为了演示效果，这里我们设定一个具体的高度 */
}
.ai-feature {
  /*max-width: 100%; !* 确保图片的最大宽度不超过容器宽度 *!*/
  /*max-height: 100%; !* 确保图片的最大高度不超过容器高度 *!*/
  height: 280px; /* 调整图标大小 */
  display: block;
  margin: 10px auto; /* 居中显示 */
  /*object-fit: contain; !* 保持宽高比，同时确保图片内容都可见 *!*/
}


.base-intro {
  margin: 2px 0px;
  font-size: 16px;
  font-weight: bold;
}

</style>