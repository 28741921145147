<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>

        <el-alert type="info" :closable="false">
          <template v-slot:title>
            <p style="font-size: 16px; color: black">
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
          </template>
        </el-alert>
      </el-header>

      <el-main class="overflow-hid">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)"
             :id="item.questionId">
          <div class="content">
            <p class="note">
              <strong>注意：</strong>当前页面共有两道题练习题，以方便您熟悉任务。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="10">

              <el-tag class="base-intro"><strong>家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"  :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">父亲的教育程度（Fedu）</template>{{item.Fedu}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">母亲的教育程度（Medu）</template>{{item.Medu}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="warning" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"   :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">是否想接受高等教育（Highter）</template>{{item.higher}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">每周课余学习时间（studytime）</template>{{item.studytime}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">缺勤次数（absence）</template>{{item.absences}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item><template slot="label">历次课程未通过次数（failure）</template>{{ item.failures}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第一学期成绩（G1）</template>{{item.G1}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第二学期成绩（G2）</template>{{item.G2}}</el-descriptions-item>
                </el-descriptions>
              </div>

            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="13">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <!--下拉框 0-20分-->
                <div >
                  <span> 请您预测该学生的最终学习成绩（G3）：</span>
                  <br><br>
                  <el-cascader :options="options"
                               v-model="form.answers[item.questionId].score"
                               @change="recordSelectionTime(item.questionId)"
                               :props="{ expandTrigger: 'hover' }"
                  >
                  </el-cascader><br>
                </div>
                <el-row v-show="showAnswerCtrl">
                  <el-col>
                    <br>
                    <strong>该学生的最终学习成绩为：{{item.G3}} 分</strong>
                    <br>
                    <br>
                  </el-col>
                </el-row>
                <br>
                  <el-button type="primary" round v-on:click="nextQuestion(index)"
                             v-show="index<1 && showAnswerCtrl">下一题
                  </el-button>
                  <el-button type="primary" round v-on:click="goToQuestionCollectionPage()"
                             v-show="index ===1 && showAnswerCtrl">结束练习过程，进入正式实验环节
                  </el-button>
                  <br><br>
                  <el-button type="primary" round v-on:click="showAnswer(index)"
                             v-show="form.answers[item.questionId].score && !showAnswerCtrl">展示答案
                  </el-button>

              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  props: ['questionCollectionBeforeStudyForm'],
  mounted() {
    // console.log("当前页为学习页,上个页面的数据 questionCollectionBeforeStudyForm", JSON.stringify(this.questionCollectionBeforeStudyForm));
  },
  name: "question-collection-study",
  created() {
    this.initializeData();
    this.form.pageLoadTime = this.getNowDate();
  },
  data() {
    return {
      showIndex: 0,
      showAnswerCtrl: false,
      form: {
        answers: {},
        pageLoadTime: null
      },
      options : [{"label":"分数区间:16-20","children":[{"value":"20","label":"分数为:20"},{"value":"19","label":"分数为:19"},{"value":"18","label":"分数为:18"},{"value":"17","label":"分数为:17"},{"value":"16","label":"分数为:16"}]},{"label":"分数区间:11-15","children":[{"value":"15","label":"分数为:15"},{"value":"14","label":"分数为:14"},{"value":"13","label":"分数为:13"},{"value":"12","label":"分数为:12"},{"value":"11","label":"分数为:11"}]},{"label":"分数区间:6-10","children":[{"value":"10","label":"分数为:10"},{"value":"9","label":"分数为:9"},{"value":"8","label":"分数为:8"},{"value":"7","label":"分数为:7"},{"value":"6","label":"分数为:6"}]},{"label":"分数区间:1-5","children":[{"value":"5","label":"分数为:5"},{"value":"4","label":"分数为:4"},{"value":"3","label":"分数为:3"},{"value":"2","label":"分数为:2"},{"value":"1","label":"分数为:1"},{"value":"0","label":"分数为:0"}]}],
      optionsX:[{value:'通过',label:'通过'},{value: '未通过',label: '未通过'}],
      optionsA:[{value:'A',label:'A'},{value:'B',label:'B'},{value:'C',label:'C'},{value:'D',label:'D'},{value:'F',label:'F'}],
      optionsB:[{value:0,label:0},{value:1,label:1},{value:2,label:2},{value:3,label:3},{value:4,label:4},{value:5,label:5},{value:6,label:6},{value:7,label:7},{value:8,label:8},{value:9,label:9},{value:10,label:10},{value:11,label:11},{value:12,label:12},{value:13,label:13},{value:14,label:14},{value:15,label:15},{value:16,label:16},{value:17,label:17},{value:18,label:18},{value:19,label:19},{value:20,label:20}],
      questionList:[{"questionId":"question-collection_before_AI-id-1","ID":131,"G3":8,"G2":9,"G1":10,"Category":"G1>G2>G3","higher":"否","Medu":"基础教育（5-9年级）","studytime":"2 - 5小时","Fedu":"基础教育（1-4年级）","failures":3,"absences":10},{"questionId":"question-collection_before_AI-id-2","ID":20,"G3":14,"G2":13,"G1":12,"Category":"G3>G2>G1","higher":"是","Medu":"高等教育（大学）","studytime":"2 - 5小时","Fedu":"中等教育（高中）","failures":0,"absences":0}],
    }
  }, methods: {
    initializeData() {
      // 初始化答案结构，包括选中值和时间
      this.questionList.forEach(question => {
        this.$set(this.form.answers, question.questionId, {score: null, scoreTime: null});
      });
      // console.log(JSON.stringify(this.form))
    }, whetherShow(index) {
      return this.showIndex === index;
    }, convertObj2Array(obj) {
      let list = [];
      list.push(obj);
      return list;
    }, nextQuestion(index) {
      this.showIndex = index + 1;
      this.showAnswerCtrl = false;
    }, showAnswer(questionId) {
      let result = null;
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].questionId === questionId) {
          result = this.questionList[i].G3;
          break;
        }
      }
      this.showAnswerCtrl = true;
      return result;
    }, recordSelectionTime(questionId) {
      // 记录选项被选中的当前时间
      this.form.answers[questionId].scoreTime = this.getNowDate();
    }, goToQuestionCollectionPage() {
      const h = this.$createElement;
      this.$notify({
        title: '问卷小测试已通过！',
        duration: 1500,
        message: h('i', {style: 'color: teal'}, '转到正式测试页面')
      });

      let arr = Object.entries(this.form.answers).map(([key, value]) => {
        return {id: key, ...value};
      });
      let paramData = {pageLoadTime: this.form.pageLoadTime, answers: arr};

      this.$router.push({
        name: 'questionCollection',
        params: {
          questionCollectionStudyForm: paramData,
          questionCollectionBeforeStudyForm: this.questionCollectionBeforeStudyForm
        }
      });
    }, get(key) {
      return sessionStorage.getItem(key);
    }, set(key, value) {
      sessionStorage.setItem(key, value);
    }, getNowDate() {
      let date = new Date();
      let sign2 = ":";
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let minutes = date.getMinutes(); // 分
      let seconds = date.getSeconds() //秒
      // let weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      // let week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    }

  }
}
</script>

<style scoped>

.home-container {
  min-height: 100vh;
  height: 100%;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 8px;
}

.base-intro {
  margin: 8px 0px;
  font-size: 14px;
}

.content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}
.center{
  text-align: center;
}
.note {
  background-color: #ffefd5; /* 浅黄色背景 */
  padding: 10px;
  border-left: 3px solid #ffa07a; /* 浅橙色边框 */
  margin: 2px;
}

.note strong {
  color: #d2691e; /* 加深的橙色文字，增加注意力 */
}
.note-answer {
  background-color: #71e564; /* 浅黄色背景 */
  padding: 10px;
  border-left: 3px solid #387a29;
}

.note-answer strong {
  color: #2b51cc;
}

.question{
  margin-top: 16px;
  padding-top: 10px; /* 设置顶部内边距 */
  padding-right: 25px; /* 设置右侧内边距 */
  padding-bottom: 20px; /* 设置底部内边距 */
  padding-left: 25px; /* 设置左侧内边距 */
}

.main-q {
  margin-top: 6px;
  margin-bottom: 6px;
}

.text-block {
  /*text-align: center; !* 文本居中 *!*/
  margin: 20px 0px;
  /* 根据需要添加更多样式 */
  /*padding: 50px 0px;*/
}

.task{
  line-height: 1.8;
}

</style>