<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
        <el-alert type="info" :closable="false">
          <template v-slot:title>
            <p style="font-size: 16px; color: black">
              <strong>注意:</strong> 在整个实验过程中，请 <b style="color: darkred;">不要</b> 刷新浏览器。否则将丢失所有数据。
            </p>
          </template>
        </el-alert>
      </el-header>

      <el-main>
        <el-form :model="form" ref="form" label-width="150px">
          <h3>在实验开始之前，请您先填写以下基本信息：</h3>
          <el-form-item
              prop="email"
              label="邮箱"
              :rules="[{ required: true, message: '请输入邮箱地址', trigger: 'blur' },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
            <el-input v-model="form.email" placeholder="请输入正确的邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="order" label="编号" :rules="[{ required: true, message: '请输入编号', trigger: 'change' }]">
            <el-input
                v-model="form.order"
                placeholder="请输入编号"
                maxlength="12"
                show-word-limit>
            </el-input>
          </el-form-item>

          <el-form-item label="性别" prop="gender"
                        :rules=" [{ required: true, message: '请选择性别', trigger: 'change' }]">
            <el-radio-group v-model="form.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="年龄" prop="age" :rules="[{ required: true, message: '请选择年龄', trigger: 'change' }]">
            <el-select v-model="form.age" placeholder="请选择年龄">
              <el-option label="18岁以下" value="18岁以下"></el-option>
              <el-option label="18~24岁" value="18~24岁"></el-option>
              <el-option label="25~30岁" value="25~30岁"></el-option>
              <el-option label="31~40岁" value="31~40岁"></el-option>
              <el-option label="41~50岁" value="41~50岁"></el-option>
              <el-option label="51~60岁" value="51~60岁"></el-option>
              <el-option label="61岁及以上" value="61岁及以上"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="最高学历" prop="education" :rules="[{ required: true, message: '请选择学历', trigger: 'change' }]">
            <el-select v-model="form.education" placeholder="请选择学历">
              <el-option label="高中及以下" value="高中及以下"></el-option>
              <el-option label="大学专科" value="大学专科"></el-option>
              <el-option label="大学本科" value="大学本科"></el-option>
              <el-option label="硕士研究生及以上" value="硕士研究生及以上"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="您大学所学专业是" prop="major" :rules=" [{ required: true, message: '请输入大学专业', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }]">
            <el-input v-model="form.major" placeholder="请输入大学专业"></el-input>
          </el-form-item>

          <el-form-item label="您的职业" prop="job"
                        :rules=" [{ required: true, message: '请选择职业', trigger: 'change' }]">
            <el-radio-group v-model="form.job">
              <el-radio label="学生"></el-radio>
              <el-radio label="中小学教师"></el-radio>
              <el-radio label="大学教师"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">提交</el-button>
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "UserInfo",
  data() {
    return {
      form: {
        userId: null,
        order: null,
        answerId: null,
        answerIsNull: false,
        questionAnswerIsNull: null,
        scaleAnswerIsNull: null,
        email: null,
        gender: null,
        job: null,
        age: null,
        education: null,
        major: null,
        randomQuestion: [],
      }
    }
  }, methods: {
//     sessionStorage
//     临时存储，为每一个数据源维持一个存储区域，在浏览器打开期间存在，包括页面重新加载。
//
//     localStorage
//     长期存储，与 sessionStorage 一样，但是浏览器关闭后，数据依然会一直存在。
    add(key, value) {
      sessionStorage.setItem(key, value);
    },
    get(key) {
      return sessionStorage.getItem(key);
    },
    del(key) {
      sessionStorage.removeItem(key);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.randomQuestion = this.shuffle(['before', 'concurrent', 'after']);
          let param = {
            email: this.form.email,
            extendInfo: JSON.stringify(this.form)
          }
          axios.post("api/question/save_user_info", param).then(res => {
            var data = res.data;
            if ("200" === data.code) {
              this.form.userId = data.data.userId;
              this.form.step = data.data.step;
              this.add('commonUserInfo', JSON.stringify(this.form));
              this.directIndexPage();
            } else {
              const h = this.$createElement;
              this.$notify.error({
                title: '基础信息保存失败！',
                duration: 3000,
                message: h('i', {style: 'color: teal'}, data.msg)
              });
            }
          });
        } else {
          return false;
        }
      });
    }, directIndexPage() {
      const h = this.$createElement;
      this.$notify({
        title: '基础信息保存成功！',
        duration: 1500,
        message: h('i', {style: 'color: teal'}, '1.5s 后跳转回首页')
      });
      setTimeout(this.openIndex, 1500);
    }, openIndex() {
      this.$router.push('/index');
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    shuffle(arr) {
      let l = arr.length
      let index, temp
      while (l > 0) {
        index = Math.floor(Math.random() * l)
        temp = arr[l - 1]
        arr[l - 1] = arr[index]
        arr[index] = temp
        l--
      }
      return arr
    }

  }
}
</script>


<style scoped>
body {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background: #f0f0f0; /* 可选的背景颜色 */
}

.container {
  width: 75vw; /* 视口宽度的75% */
  height: 96vmin; /* 确保.el-container也铺满高度 */
  background-color: white; /* 容器背景颜色，根据需要调整 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* 可选的阴影效果 */
  overflow: auto; /* 如果内容超出容器大小，则允许滚动 */
}

/* Element UI容器样式调整 */
.el-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* 确保.el-container也铺满高度 */
}

.el-header, .el-footer {
  flex: 0 0 auto; /* 不允许header和footer伸缩 */
}

.el-main {
  flex: 1; /* 允许main区域填充剩余空间 */
  overflow: auto; /* 如果内容超出，则允许滚动 */
  margin-top: 5%; /* 或根据实际需要调整这个值 */
}

.ai-icon {
  width: 400px;
}

.welcome-section ul {
  border: 2px solid #cccccc; /* 灰色边框 */
  background-color: #f0f0f0; /* 浅灰色背景 */
  padding: 15px; /* 文本与边框的间距 */
  margin: 10px 0; /* 上下外边距 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 可选的轻微阴影效果 */
  border-radius: 1px; /* 可选的边框圆角 */
  list-style-position: inside; /* 确保序号在边框内部 */
  line-height: 24px;
}

.welcome-section ul li {
  padding-left: 2em; /* 列表项内容和项目符号的缩进 */
}

.consent-intro {
  border: 2px solid #f0f0f0; /* 灰色边框 */
  background-color: white; /* 浅灰色背景 */
  padding: 10px; /* 文本与边框的间距 */
  margin: 10px 0; /* 上下外边距 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 可选的轻微阴影效果 */
  border-radius: 1px; /* 可选的边框圆角 */
  list-style-position: inside; /* 确保序号在边框内部 */
  line-height: 23px;
}

.custom-button .router-link-custom {
  text-decoration: none; /* 移除下划线 */
  font-family: '宋体', 'SimSun', sans-serif; /* 设置字体为宋体，黑体 */
  color: black; /* 文字颜色设置为黑色 */
}

.Step-bar {
  height: 300px;
}

.text-block {
  text-align: center; /* 文本居中 */
  margin: 20px;
  /* 根据需要添加更多样式 */
}

/* 确保栅格内的内容均匀分布 */
.el-row {
  width: 100%; /* 占满屏幕宽度 */
}

.row-bg {
  margin: 0 auto; /* 如果需要，可调整外边距确保整体居中 */
}
</style>