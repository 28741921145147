import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.axios = axios

new Vue({
  router:router,
  render: h => h(App)
}).$mount('#app')
