<template>
  <div class="container">
    <el-container class="home-container">
      <el-header>
        <h1>人工智能辅助决策实验</h1>
      </el-header>
      <!--模版1 AI解释 人-->
      <el-main class="overflow-hid" v-if="userIdMold === 1" style="margin: 0;padding: 0 20px 20px 20px;">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)" :id="item.questionId">
          <div class="content">
            <p class="task" >
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
            <p class="note" >
              <strong>注意：</strong>
              在接下来的任务中，AI助手将给出它推荐的学生成绩预测信息，您可以决定信任它或不信任它。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="8">

              <el-tag class="base-intro"><strong>家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"  :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">父亲的教育程度（Fedu）</template>{{item.Fedu}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">母亲的教育程度（Medu）</template>{{item.Medu}}</el-descriptions-item>
                </el-descriptions>
              </div>
              <el-tag type="warning" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"   :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">是否想接受高等教育（Highter）</template>{{item.higher}}</el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">每周课余学习时间（studytime）</template>
                    <div style="display: flex; align-items: center;">
                      <el-progress :percentage="getPercentage(item.studytime)" :show-text="false" style="flex: 1; margin-right: 10px;"></el-progress>
                      <span>{{item.studytime}}</span>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item><template slot="label">缺勤次数（absence）</template>{{item.absences}}</el-descriptions-item>
                </el-descriptions>
              </div>
              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item><template slot="label">历次课程未通过次数（failure）</template>{{ item.failures}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第一学期成绩（G1）</template>{{item.G1}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第二学期成绩（G2）</template>{{item.G2}}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="15">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <el-form ref="form" :model="form" class="demo-ruleForm">
                <!-- 导航栏1 -->
                <el-tabs type="border-card"  class="note-answer-out">
                  <el-tab-pane label="预测学生的最终学习成绩">
                    <el-row>
                      <el-col class="demo-image__placeholder" :span="3">
                        <div class="block">
                          <el-image :src="imgSrc" class="ai-robot"></el-image>
                        </div>
                      </el-col>
                      <el-col  :span="21"  style="line-height: 1.6">
                        <br>
                        <span>{{ output }}</span>
                        <br>
                        <span>{{ output2 }}</span>
                        <br>-----------------------------------------------------------------------------------------
                      </el-col>
                      <el-col  :span="24" class="note-answer" v-if="delayShow">
                        <span>{{ output3 }}</span>
                        <el-table :data="splitTableData"
                                  :span-method="objectSpanMethod"
                                  border style="width: 100%; margin-top: 20px">
                          <el-table-column prop="feature" label="特征" > </el-table-column>
                          <el-table-column prop="point" label="指标"> </el-table-column>
                          <el-table-column prop="effect" label="影响">
                            <template slot-scope="scope">
                              <el-tag :type="scope.row.effect === '正面' ?  'success' : 'danger'" disable-transitions>{{scope.row.effect}}</el-tag>
                            </template>
                          </el-table-column>
                          <el-table-column prop="degree" label="程度">
                            <template  slot-scope="scope">
                              <el-button :type="scope.row.degree <= 3 ? 'primary' : '' " size="mini" round>{{scope.row.degree}}</el-button>
                            </template>
                          </el-table-column>
                          <el-table-column prop="explain"  label="解释" width=420%> </el-table-column>
                        </el-table>

                      </el-col>
                      <el-col :span="0.5">
                      </el-col>
                    </el-row>
                    <el-form-item v-if="delayShow">
                      <span> 请您预测该学生的最终学习成绩（G3）：</span><br>
                      <el-cascader :options="options"
                                   v-model="form.answers[item.questionId].score"
                                   @change="recordSelectionTime(item.questionId,'score')"
                                   :props="{ expandTrigger: 'hover' }"
                      >
                      </el-cascader>
                      <br>
                      <div  v-show="form.answers[item.questionId].score"
                            @click="recordSelectionTime(item.questionId,'confident')">
                        <span>我能够正确预测该学生的最终学习成绩</span>
                        <el-rate :max='7'
                                 :texts='confidenceTextArr'
                                 show-text
                                 void-color='#606266'
                                 v-model="form.answers[item.questionId].confident" >
                        </el-rate>
                      </div>
                      <div  v-show="form.answers[item.questionId].confident"
                            @click="recordSelectionTime(item.questionId,'trustAi')">
                        <span>我信任AI给出的决策</span>
                        <el-rate :max='7'
                                 :texts='confidenceTextArr'
                                 show-text
                                 void-color='#606266'
                                 v-model="form.answers[item.questionId].trustAi" >
                        </el-rate>
                      </div>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>
                  <br>
                  <el-button type="primary" round v-on:click="nextQuestion(index)"
                             v-show="index < questionList.length - 1 && form.answers[item.questionId].trustAi">
                    下一题
                  </el-button>
                  <el-button type="primary" round v-on:click="goToNextPage()"
                             v-show="index === questionList.length - 1 && form.answers[item.questionId].trustAi">
                    该阶段测试结束,进入下一环节。
                  </el-button>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!--模版2 AI无解释 人-->
      <el-main class="overflow-hid" v-if="userIdMold === 2"  style="margin: 0;padding: 0 20px 20px 20px;">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)" :id="item.questionId">
          <div class="content">
            <p class="task" >
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
            <p class="note" >
              <strong>注意：</strong>
              在接下来的任务中，AI助手将给出它推荐的学生成绩预测信息，您可以决定信任它或不信任它。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="8">

              <el-tag class="base-intro"><strong>家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"  :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">父亲的教育程度（Fedu）</template>{{item.Fedu}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">母亲的教育程度（Medu）</template>{{item.Medu}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="warning" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"   :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">是否想接受高等教育（Highter）</template>{{item.higher}}</el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">每周课余学习时间（studytime）</template>
                    <div style="display: flex; align-items: center;">
                      <el-progress :percentage="getPercentage(item.studytime)" :show-text="false" style="flex: 1; margin-right: 10px;"></el-progress>
                      <span>{{item.studytime}}</span>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item><template slot="label">缺勤次数（absence）</template>{{item.absences}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item><template slot="label">历次课程未通过次数（failure）</template>{{ item.failures}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第一学期成绩（G1）</template>{{item.G1}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第二学期成绩（G2）</template>{{item.G2}}</el-descriptions-item>
                </el-descriptions>
              </div>

            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="15">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <el-tabs type="border-card" >
                  <el-tab-pane label="预测学生的最终学习成绩">
                    <el-form ref="form" :model="form" class="demo-ruleForm">

                      <el-row>
                        <el-col class="demo-image__placeholder" :span="3">
                          <div class="block">
                            <el-image :src="imgSrc" class="ai-robot"></el-image>
                          </div>
                        </el-col>
                        <el-col  :span="21" >
                          <br>
                          <span>{{ output }}</span>
                        </el-col>
                      </el-row>
<!--                        -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
                      <el-form-item  v-if="delayShow">
                        <span> 请您预测该学生的最终学习成绩（G3）：</span><br>
                        <el-cascader :options="options"
                                     v-model="form.answers[item.questionId].score"
                                     @change="recordSelectionTime(item.questionId,'score')"
                                     :show-all-levels="false"
                                     :props="{ expandTrigger: 'hover' }"
                        >
                        </el-cascader>
                      </el-form-item>
                      <br>
                      <div  v-show="form.answers[item.questionId].score"
                            @click="recordSelectionTime(item.questionId,'confident')">
                        <span>我能够正确预测该学生的最终学习成绩</span>
                        <el-rate :max='7'
                                 :texts='confidenceTextArr'
                                 show-text
                                 void-color='#606266'
                                 v-model="form.answers[item.questionId].confident" >
                        </el-rate>
                      </div>
                      <br>
                      <div  v-show="form.answers[item.questionId].confident" @click="recordSelectionTime(item.questionId,'trustAi')">
                        <span>我信任AI给出的决策</span>
                        <el-rate :max='7'
                                 :texts='confidenceTextArr'
                                 show-text
                                 void-color='#606266'
                                 v-model="form.answers[item.questionId].trustAi" >
                        </el-rate>
                      </div>
                      <br>

                      <el-button type="primary" round v-on:click="nextQuestion(index)"
                                 v-show="index < questionList.length - 1 && form.answers[item.questionId].trustAi">下一题
                      </el-button>

                      <el-button type="primary" round v-on:click="goToNextPage()"
                                 v-show="index === questionList.length - 1 && form.answers[item.questionId].trustAi">
                        该阶段测试结束,进入下一环节。
                      </el-button>

                    </el-form>
                  </el-tab-pane>
                </el-tabs>


              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!--模版3 人 AI解释 人-->
      <el-main class="overflow-hid" v-if="userIdMold === 3"  style="margin: 0;padding: 0 20px 20px 20px;">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)" :id="item.questionId">
          <div class="content">
            <p class="task" >
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
            <p class="note" >
              <strong>注意：</strong>
              在接下来的任务中，AI助手将给出它推荐的学生成绩预测信息，您可以决定信任它或不信任它。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="8">

              <el-tag class="base-intro"><strong>家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"  :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">父亲的教育程度（Fedu）</template>{{item.Fedu}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">母亲的教育程度（Medu）</template>{{item.Medu}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="warning" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"   :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">是否想接受高等教育（Highter）</template>{{item.higher}}</el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">每周课余学习时间（studytime）</template>
                    <div style="display: flex; align-items: center;">
                      <el-progress :percentage="getPercentage(item.studytime)" :show-text="false" style="flex: 1; margin-right: 10px;"></el-progress>
                      <span>{{item.studytime}}</span>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item><template slot="label">缺勤次数（absence）</template>{{item.absences}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item><template slot="label">历次课程未通过次数（failure）</template>{{ item.failures}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第一学期成绩（G1）</template>{{item.G1}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第二学期成绩（G2）</template>{{item.G2}}</el-descriptions-item>
                </el-descriptions>
              </div>

            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="15">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <el-form ref="form" :model="form" class="demo-ruleForm">
                  <el-tabs type="border-card" class="note-answer-out">
                    <el-tab-pane label="预测学生的最终学习成绩">
                      <el-row>
                        <el-form-item>
                          <span> 请您预测该学生的最终学习成绩（G3）：</span><br>
                          <el-cascader :options="options"
                                       v-model="form.answers[item.questionId].score"
                                       @change="recordSelectionTimeAndOutPut(index,item.questionId,'score')"
                                       :show-all-levels="false"
                                       :props="{ expandTrigger: 'hover' }"
                                       :disabled="form.answers[item.questionId].score != null">
                          </el-cascader>
                        </el-form-item>
                      </el-row>
                      <el-row v-if="form.answers[item.questionId].score != null">
                        <el-col class="demo-image__placeholder" :span="3">
                          <div class="block">
                            <el-image :src="imgSrc" class="ai-robot"></el-image>
                          </div>
                        </el-col>
                        <el-col  :span="21" style="line-height: 1.6">
                          <br>
                          <span>{{ output }}</span>
                          <br>
                          <span>{{ output2 }}</span>
                          <br>-----------------------------------------------------------------------------------------
                        </el-col>
                        <el-col  :span="24" class="note-answer">
                          <span>{{output3}}</span>

                          <el-table :data="splitTableData"
                                    :span-method="objectSpanMethod"
                                    border style="width: 100%; margin-top: 20px" v-if="delayShow">
                            <el-table-column prop="feature" label="特征" > </el-table-column>
                            <el-table-column prop="point" label="指标"> </el-table-column>
                            <el-table-column prop="effect" label="影响">
                              <template slot-scope="scope">
                                <el-tag :type="scope.row.effect === '正面' ?  'success' : 'danger'" disable-transitions>{{scope.row.effect}}</el-tag>
                              </template>
                            </el-table-column>
                            <el-table-column prop="degree" label="程度">
                              <template  slot-scope="scope">
                                <el-button :type="scope.row.degree <= 3 ? 'primary' : '' " size="mini" round>{{scope.row.degree}}</el-button>
                              </template>
                            </el-table-column>
                            <el-table-column prop="explain"  label="解释" width=420%> </el-table-column>
                          </el-table>

                        </el-col>
                        <el-col :span="0.5">
                        </el-col>
                      </el-row>
                      <el-form-item v-if="form.answers[item.questionId].score != null && delayShow">
                        <span> 请您预测该学生的最终学习成绩（G3）：</span><br>
                        <el-cascader :options="options"
                                     v-model="form.answers[item.questionId].reScore"
                                     @change="recordSelectionTime(item.questionId,'reScore')"
                                     :props="{ expandTrigger: 'hover' }"
                        >
                        </el-cascader><br>
                        <div  v-show="form.answers[item.questionId].reScore"
                              @click="recordSelectionTime(item.questionId,'confident')">
                          <span>我能够正确预测该学生的最终学习成绩</span>
                          <el-rate :max='7'
                                   :texts='confidenceTextArr'
                                   show-text
                                   void-color='#606266'
                                   v-model="form.answers[item.questionId].confident" >
                          </el-rate>
                        </div>
                        <div  v-show="form.answers[item.questionId].confident"
                              @click="recordSelectionTime(item.questionId,'trustAi')">
                          <span>我信任AI给出的决策</span>
                          <el-rate :max='7'
                                   :texts='confidenceTextArr'
                                   show-text
                                   void-color='#606266'
                                   v-model="form.answers[item.questionId].trustAi" >
                          </el-rate>
                        </div>
                      </el-form-item>
                    </el-tab-pane>
                  </el-tabs>
                  <br>
                  <el-button type="primary" round v-on:click="nextQuestionNotOutUpt(index)"
                             v-show="index < questionList.length - 1 && form.answers[item.questionId].trustAi">下一题
                  </el-button>
                  <el-button type="primary" round v-on:click="goToNextPage()"
                             v-show="index === questionList.length - 1 && form.answers[item.questionId].trustAi">
                    该阶段测试结束,进入下一环节。
                  </el-button>

                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!--模版4 人 AI无解释 人-->
      <el-main class="overflow-hid" v-if="userIdMold === 4"  style="margin: 0;padding: 0 20px 20px 20px;">
        <div v-for="(item ,index) in questionList" :key="item.questionId" v-show="whetherShow(index)" :id="item.questionId">
          <div class="content">
            <p class="task" >
              <strong>您的任务是：</strong>预测该学生的最终学习成绩，成绩为20分制。
              对于每项任务，您都会看到一个显示学生信息的表格，共有三类特征及其子属性，如下所示。
            </p>
            <p class="note" >
              <strong>注意：</strong>
              在接下来的任务中，AI助手将给出它推荐的学生成绩预测信息，您可以决定信任它或不信任它。
            </p>
          </div>

          <el-row type="flex">
            <el-col :span="8">

              <el-tag class="base-intro"><strong>家庭背景相关特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"  :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">父亲的教育程度（Fedu）</template>{{item.Fedu}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">母亲的教育程度（Medu）</template>{{item.Medu}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="warning" class="base-intro"><strong>学习行为特征 :</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top"   :column="1" :size="''" border>
                  <el-descriptions-item ><template slot="label">是否想接受高等教育（Highter）</template>{{item.higher}}</el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">每周课余学习时间（studytime）</template>
                    <div style="display: flex; align-items: center;">
                      <el-progress :percentage="getPercentage(item.studytime)" :show-text="false" style="flex: 1; margin-right: 10px;"></el-progress>
                      <span>{{item.studytime}}</span>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item><template slot="label">缺勤次数（absence）</template>{{item.absences}}</el-descriptions-item>
                </el-descriptions>
              </div>

              <el-tag type="success" class="base-intro"><strong>学习表现特征:</strong></el-tag>
              <div class="grid-content bg-purple">
                <el-descriptions class="margin-top" :column="1" :size="''" border>
                  <el-descriptions-item><template slot="label">历次课程未通过次数（failure）</template>{{ item.failures}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第一学期成绩（G1）</template>{{item.G1}}</el-descriptions-item>
                  <el-descriptions-item><template slot="label">第二学期成绩（G2）</template>{{item.G2}}</el-descriptions-item>
                </el-descriptions>
              </div>

            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="15">
              <h3 class="main-q">根据左边表格回答问题 （{{ index + 1 }}/{{ questionList.length }}）</h3>
              <div class="grid-content bg-purple question">
                <el-form ref="form" :model="form" class="demo-ruleForm">
                  <el-tabs type="border-card" >
                    <el-tab-pane label="预测学生的最终学习成绩">
                      <el-row>
                        <el-form-item>
                          <span> 请您预测该学生的最终学习成绩（G3）：</span><br>
                          <el-cascader :options="options"
                                       v-model="form.answers[item.questionId].score"
                                       @change="recordSelectionTimeAndOutPut(index,item.questionId,'score')"
                                       :show-all-levels="false"
                                       :props="{ expandTrigger: 'hover' }"
                                       :disabled="form.answers[item.questionId].score != null">
                          </el-cascader>
                        </el-form-item>
                      </el-row>
                      <el-row v-if="form.answers[item.questionId].score != null">
                        <el-col class="demo-image__placeholder" :span="3">
                          <div class="block">
                            <el-image :src="imgSrc" class="ai-robot"></el-image>
                          </div>
                        </el-col>
                        <el-col  :span="21" >
                          <br>
                          <span>{{output}}</span>
<!--                          <br>-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
                        </el-col>
                        <el-col :span="0.5"></el-col>
                      </el-row>

                      <el-form-item v-if="form.answers[item.questionId].score != null && delayShow ">
                        <span> 请您再次预测该学生的最终学习成绩（G3）：</span><br>
                        <el-cascader :options="options"
                                     v-model="form.answers[item.questionId].reScore"
                                     @change="recordSelectionTime(item.questionId,'reScore')"
                                     :props="{ expandTrigger: 'hover' }"
                        >
                        </el-cascader>

                        <div  v-show="form.answers[item.questionId].reScore"
                              @click="recordSelectionTime(item.questionId,'confident')">
                          <br>
                          <span>我能够正确预测该学生的最终学习成绩</span>
                          <el-rate :max='7'
                                   :texts='confidenceTextArr'
                                   show-text
                                   void-color='#606266'
                                   v-model="form.answers[item.questionId].confident" >
                          </el-rate>
                        </div>
                        <br>
                        <div  v-show="form.answers[item.questionId].confident"
                              @click="recordSelectionTime(item.questionId,'trustAi')">
                          <span>我信任AI给出的决策</span>
                          <el-rate :max='7'
                                   :texts='confidenceTextArr'
                                   show-text
                                   void-color='#606266'
                                   v-model="form.answers[item.questionId].trustAi" >
                          </el-rate>
                        </div>
                      </el-form-item>
                    </el-tab-pane>
                  </el-tabs>
                  <br>
                  <el-button type="primary" round v-on:click="nextQuestionNotOutUpt(index)"
                             v-show="index < questionList.length - 1 && form.answers[item.questionId].trustAi">下一题
                  </el-button>
                  <el-button type="primary" round v-on:click="goToNextPage()"
                             v-show="index === questionList.length - 1 && form.answers[item.questionId].trustAi">
                    该阶段测试结束,进入下一环节。
                  </el-button>

                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "question-collection-ai",
  props: ['questionCollectionStudyForm', 'questionCollectionForm', 'questionCollectionBeforeStudyForm'],
  mounted() {
    let commonUserInfoJson = this.get('commonUserInfo');
    if (null != commonUserInfoJson) {
      let commonUserObj = JSON.parse(commonUserInfoJson);
      if (null != commonUserObj) {
        let userId = commonUserObj.userId;
        if (null != userId) {
          this.userIdMold = (userId % 4) + 1;
          // this.userIdMold = 1;
          if(this.userIdMold ===1 ||this.userIdMold ===2){
            this.typeWriter(0);
          }
          // console.log("当前题型: " + this.userIdMold  +", "+ this.moldExplain[this.userIdMold]);
        }
      }
    }
  },
  created() {
    this.initializeData();
    this.handleTaleClick({label:'预测学生最终成绩分数'});
    this.form.pageLoadTime = this.getNowDate();
  },
  data() {
    return {
      delayShow: false,
      output: "",
      output2: "",
      output3: "",
      splitTableData: [],
      concurrentImgShow:[""],
      confidenceTextArr:['非常不同意','不同意','比较不同意','中立','比较同意','同意','非常同意'],
      confidenceTextList:['非常没有信心','没有信心','比较没有信心','中立','比较同意','同意','非常同意'],
      paragraphs: [],
      imgSrc:  require('@/img/AI-robot3.png'),
      moldExplain:["","AI有解释","AI无解释","人先选，AI有解释","人先选，AI无解释"],
      userIdMold: 0,
      showIndex: 0,
      form: {
        answers: {},
        pageLoadTime: null
      },
      optionsX:[{value:'通过',label:'通过'},{value: '未通过',label: '未通过'}],
      optionsA:[{value:'A',label:'A'},{value:'B',label:'B'},{value:'C',label:'C'},{value:'D',label:'D'},{value:'F',label:'F'}],
      optionsB:[{value:0,label:0},{value:1,label:1},{value:2,label:2},{value:3,label:3},{value:4,label:4},{value:5,label:5},{value:6,label:6},{value:7,label:7},{value:8,label:8},{value:9,label:9},{value:10,label:10},{value:11,label:11},{value:12,label:12},{value:13,label:13},{value:14,label:14},{value:15,label:15},{value:16,label:16},{value:17,label:17},{value:18,label:18},{value:19,label:19},{value:20,label:20}],
      options : [{"label":"分数区间:16-20","children":[{"value":"20","label":"分数为:20"},{"value":"19","label":"分数为:19"},{"value":"18","label":"分数为:18"},{"value":"17","label":"分数为:17"},{"value":"16","label":"分数为:16"}]},{"label":"分数区间:11-15","children":[{"value":"15","label":"分数为:15"},{"value":"14","label":"分数为:14"},{"value":"13","label":"分数为:13"},{"value":"12","label":"分数为:12"},{"value":"11","label":"分数为:11"}]},{"label":"分数区间:6-10","children":[{"value":"10","label":"分数为:10"},{"value":"9","label":"分数为:9"},{"value":"8","label":"分数为:8"},{"value":"7","label":"分数为:7"},{"value":"6","label":"分数为:6"}]},{"label":"分数区间:0-5","children":[{"value":"5","label":"分数为:5"},{"value":"4","label":"分数为:4"},{"value":"3","label":"分数为:3"},{"value":"2","label":"分数为:2"},{"value":"1","label":"分数为:1"},{"value":"0","label":"分数为:0"}]}],
      questionList: [],
    }
  }, methods: {
    getPercentage(str){
      if(str ==='') return 0;
      if(str === '小于2小时') return 30;
      if(str === '2 - 5小时') return 60;
      if(str === '5 - 10 小时') return 80;
      if(str === '大于10小时') return 100;
    },
    handleTaleClick(tab){
      let question = this.questionList[this.showIndex];
      let temptable;
      if('预测学生最终成绩分数'===tab.label){
        temptable = question.feature_impact_regression;
        let obj0 ={'feature':'家庭背景','point':'Fedu','effect':temptable.Fedu_impact,'degree':temptable.Fedu_impact_sort,'explain':question.Fdu_regression_explanation};
        let obj1 ={'feature':'家庭背景','point':'Medu','effect':temptable.Medu_impact,'degree':temptable.Medu_impact_sort,'explain':question.Mdu_regression_explanation};
        let obj2 ={'feature':'学习行为','point':'Highter','effect':temptable.highter_impact,'degree':temptable.highter_impact_sort,'explain':question.Higher_regression_explanation};
        let obj3 ={'feature':'学习行为','point':'studytime','effect':temptable.studytime_impact,'degree':temptable.studytime_impact_sort,'explain':question.studytime_regression_explanation};
        let obj4 ={'feature':'学习行为','point':'absense','effect':temptable.absense_impact,'degree':temptable.absense_impact_sort,'explain':question.absence_regression_explanation};
        let obj5 ={'feature':'学习表现','point':'failure','effect':temptable.failure_impact,'degree':temptable.failure_impact_sort,'explain':question.fail_regression_explanation};
        let obj6 ={'feature':'学习表现','point':'G1','effect':temptable.G1_impact,'degree':temptable.G1_impact_sort,'explain':question.G1_regression_explanation};
        let obj7 ={'feature':'学习表现','point':'G2','effect':temptable.G2_impact,'degree':temptable.G2_impact_sort,'explain':question.G2_regression_explanation};
        this.splitTableData= null;
        this.splitTableData = [obj0,obj1,obj2,obj3,obj4,obj5,obj6,obj7];
      }
    },
    typeWriter(index) {
      this.delayShow = false;
      this.output = ""
      this.output2= ""
      this.output3= ""
      let text = '你好，我是你的AI小助手！我预测该学生的最终学习成绩为：' + this.questionList[index].G3_pre_int + '分。';
      let i = 0;
      const interval = setInterval(() => {
        if (i < text.length) {
          this.output += text[i];
          i++;
        } else {
          clearInterval(interval);
          this.typeWriter2(index)
        }
      }, 100); // 调整延迟时间（单位：毫秒）
    },
    typeWriter2(index) {
      let text = '下面我将给出我做出预测的具体原因：'
      let i = 0;
      const interval = setInterval(() => {
        if (i < text.length) {
          this.output2 += text[i];
          i++;
        } else {
          clearInterval(interval);
          this.delayShow = true;
          this.typeWriter3(index)
        }
      }, 50); // 调整延迟时间（单位：毫秒）
    },
    typeWriter3(index) {
      this.output3 = this.questionList[index].sum_regression_explanation ;
    },
    initializeData() {
      let before_ai = require('@/json/init_before_ai_question.json');
      let ai = require('@/json/init_ai_question.json');
      let after_ai= require('@/json/init_after_ai_question.json');
      this.questionList = [before_ai, ai, after_ai][JSON.parse(this.get('commonUserInfo')).randomQuestion.indexOf('concurrent')];
      this.questionList.forEach(table => {
        this.$set(this.form.answers, table.questionId, {
          id: table.ID,
          standAnswer: table.G3,
          aiAnswer: '',
          score: null,
          scoreTime: null,
          reScore: null,
          reScoreTime: null,
          trustAi: null,
          trustAiTime: null,
          confident :null ,
          confidentTime :null ,
        })
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0 || rowIndex === 2 || rowIndex === 5) {
          return { rowspan: (rowIndex === 5 ? 3 : (rowIndex === 2 ? 3 : 2)), colspan: 1 };
        } else {
          return { rowspan: 0, colspan: 0 };
        }
      }
    },
    whetherShow(index) {
      return this.showIndex === index;
    },
    nextQuestion(index) {
      this.showIndex = index + 1;
      this.handleTaleClick({label:'预测学生最终成绩分数'});
      window.scrollTo(0, 0);
      this.typeWriter(this.showIndex)
    },
    nextQuestionNotOutUpt(index) {
      this.showIndex = index + 1;
      this.handleTaleClick({label:'预测学生最终成绩分数'});
      window.scrollTo(0, 0);
    },
    recordSelectionTime(questionId, recordType) {
      if ('score' === recordType) {
        this.form.answers[questionId].score= this.form.answers[questionId].score[1];
        this.form.answers[questionId].scoreTime = this.getNowDate();
      } else if ('trustAi' === recordType) {
        this.form.answers[questionId].trustAiTime = this.getNowDate();
      } else if ('confident' === recordType) {
        this.form.answers[questionId].confidentTime = this.getNowDate();
      } else if ('reScore' === recordType) {
        this.form.answers[questionId].reScore= this.form.answers[questionId].reScore[1];
        this.form.answers[questionId].reScoreTime = this.getNowDate();
      }
    },
    recordSelectionTimeAndOutPut(index,questionId, recordType) {
      if ('score' === recordType) {
        this.form.answers[questionId].score= this.form.answers[questionId].score[1];
        this.form.answers[questionId].scoreTime = this.getNowDate();
      }
      this.typeWriter(index);
     },
    goToNextPage() {
      const h = this.$createElement;
      this.$notify({
        title: '问卷小测试已通过！',
        duration: 1500,
        message: h('i', {style: 'color: teal'}, '该阶段测试结束，进入下个答题页面')
      });

      let arr = Object.entries(this.form.answers).map(([key, value]) => {
        return {id: key, ...value};
      });
      let paramData = {pageLoadTime: this.form.pageLoadTime, answers: arr};

      this.$router.push({
        name: 'questionCollectionAfterAi',
        params: {
          questionCollectionBeforeStudyForm: this.questionCollectionBeforeStudyForm,
          questionCollectionStudyForm: this.questionCollectionStudyForm,
          questionCollectionForm: this.questionCollectionForm,
          questionCollectionAiForm: paramData,
        }
      });
    },
    get(key) {
      return sessionStorage.getItem(key);
    },
    set(key, value) {
      sessionStorage.setItem(key, value);
    },
    getNowDate() {
      let date = new Date();
      let sign2 = ":";
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let minutes = date.getMinutes(); // 分
      let seconds = date.getSeconds() //秒
      // let weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      // let week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    }

  }
}
</script>

<style scoped>

.home-container {
  min-height: 100vh;
  height: 100%;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 10px;
  font-size: 16px; /* 修改字体大小 */
  /*line-height: 10px; !* 修改行间距 *!*/
}


.base-intro {
  margin: 8px 0px;
  /*font-size: 14px;*/
  font-size: 16px; /* 修改字体大小 */
  line-height: 24px; /* 修改行间距 */
}

.content {
  font-family: Arial, sans-serif;
  line-height: 1.4;
  color: #333;
  font-size: 16px; /* 修改字体大小 */
}

.margin-top{
  font-size: 16px; /* 修改字体大小 */
  line-height: 1;
}

.note {
  background-color: #ffefd5; /* 浅黄色背景 */
  padding: 10px;
  border-left: 3px solid #ffa07a; /* 浅橙色边框 */
}

.note strong {
  color: #d2691e; /* 加深的橙色文字，增加注意力 */
}
.note-answer-out{
  /*background-color: #f0f9eb;!* 浅黄色背景 *!*/
}

.note-answer {
  /*background-color: #71e564; !* 浅黄色背景 *!*/
  background-color: #f0f9eb; /* 浅黄色背景 */
  padding: 12px;
  border-radius: 8px;
  text-align: justify;
  /*border: 1px solid #ccc; !* 边框 *!*/
  /*border-left: 3px solid #387a29;*/
  line-height: 1.6;
}

.note-answer p{
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.5;
  margin: 8px 0;
  font-size: 18px;
  text-align: justify;
}

.note-answer strong {
  color: #2b51cc;
}

.question {
  margin-top: 16px;
  padding-top: 10px; /* 设置顶部内边距 */
  padding-right: 25px; /* 设置右侧内边距 */
  padding-bottom: 20px; /* 设置底部内边距 */
  padding-left: 25px; /* 设置左侧内边距 */
}

.main-q {
  margin-top: 6px;
  margin-bottom: 6px;
}


/* 确保父容器是flex容器 */
.row {
  display: flex;
  flex-wrap: nowrap; /* 防止元素换行 */
  width: 100%; /* 容器占满整个父级宽度 */
}

/* 控制第一个div占30%的宽度 */
.demo-image__placeholder {
  flex: 0 0 20%; /* 不允许该元素增长或缩小 */
  max-width: 20%; /* 最大宽度为30% */
}

/* 控制第二个div占70%的宽度 */
div[span="19"] {
  flex: 0 0 80%; /* 不允许该元素增长或缩小 */
  max-width: 80%; /* 最大宽度为70% */
}

/* 调整图片大小 */
.ai-robot {
  max-width: 100%; /* 图片最大宽度为其容器的100% */
  height: auto; /* 高度自动调整以保持宽高比 */
}
.center{
  text-align: center;
  align-items: center;
}

</style>